import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import jwt_decode from 'jwt-decode';

import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';
import { SessionType } from '../contexts/session';
import { APIGetPaginatedParams, ApiErrorObject } from './types';
import { NewUser, UserModel, UserRecord } from '../models/User';
import Logger from '../utils/logger';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import { DecodedToken } from '../utils/AuthHelper/types';

export interface UpdateUserData {
    name: string;
}

class UserAPI {
    private static getUserAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_USER_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        userData: NewUser
    ): Promise<ApiErrorObject | UserModel> => {
        const userAPI = UserAPI.getUserAPIAxiosObj(session);

        try {
            const user = await userAPI.post<UserModel>('', userData);

            if (user.data) {
                return user.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('creating user');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating user');
    };

    public static get = async (
        session: SessionType,
        userId: string
    ): Promise<UserModel> => {
        const userAPI = UserAPI.getUserAPIAxiosObj(session);

        const { status, data } = await userAPI.get<UserModel>(`/${userId}`);

        if (status === 200 && data && data.id && data.email) {
            return data;
        } else {
            throw new Error('Error retrieving user');
        }
    };

    public static getSelf = async (
        session: SessionType
    ): Promise<UserModel> => {
        if (session.idToken) {
            const decodedToken = jwt_decode<DecodedToken>(session.idToken);

            if (decodedToken && decodedToken['custom:userId']) {
                return UserAPI.get(session, decodedToken['custom:userId']);
            }
        }

        throw new Error('Session token missing or invalid');
    };

    public static forgotPassword = async (
        email: string
    ): Promise<ApiErrorObject | string> => {
        const userAPI = UserAPI.getUserAPIAxiosObj();

        try {
            const response = await userAPI.post('/forgotpassword', {
                email
            });

            if (
                response.status === 200 &&
                response.data !== undefined &&
                response.data.code !== undefined
            ) {
                return response.data.code;
            } else {
                Logger.error(
                    'Unexpected error resetting password' +
                        JSON.stringify(response)
                );
                return generateApiUnexpectedError('resetting password');
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('resetting password');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }
    };

    public static getMany = async (
        session: SessionType,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<UserModel>> => {
        const filter: AxiosRequestConfig = {};
        const userAPI = UserAPI.getUserAPIAxiosObj(session);

        if (params) {
            filter.params = {
                ...filter.params
            };
            if (params.scanLimit) {
                filter.params.scanLimit = params.scanLimit;
            }
            if (params.lastKey) {
                filter.params.lastKey = JSON.stringify(params.lastKey);
            }
        }

        const { status, data } = await userAPI.get<
            PaginatedCollection<UserModel>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving users');
        }
    };

    public static update = async (
        session: SessionType,
        userId: string,
        userData: UserRecord
    ): Promise<ApiErrorObject | UserModel> => {
        const userAPI = UserAPI.getUserAPIAxiosObj(session);

        try {
            const { status, data } = await userAPI.put<UserModel>(
                `/${userId}`,
                userData
            );

            if (status === 200 && data && data.id) {
                return data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('updating user');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('updating user');
    };
}

export const passwordRecoveryResponseCodeRecoveryCodeSent = 'recoveryCodeSent';
export const passwordRecoveryResponseCodeTempPasswordResent =
    'temporaryPasswordResent';

export default UserAPI;
