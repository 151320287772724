import { Dialog, DialogContent, MenuItem } from '@mui/material';
import { SetStateAction, useContext } from 'react';
import { StoreContext } from '../contexts/store';
import FormTextField from '../components/forms/FormTextField';
import FormRowContainer from '../components/forms/FormRowContainer';
import FormButton from '../components/forms/FormButton';
import { Form } from 'react-final-form';
import { ObjectType } from '@advocate-insights/ms-common';
import FormTitle from '../components/forms/FormTitle';
import CookiesHelper from '../utils/cookies/CookiesHelper';
import { useCookies } from 'react-cookie';
import { CookieNames, CookiesType } from '../utils/cookies/types';

interface SelectClientProps {
    open: boolean;
    setOpen: (value: SetStateAction<boolean>) => void;
}

const SelectClient = (props: SelectClientProps): JSX.Element => {
    const [cookies, setCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
        CookieNames.UserPreferences
    ]);
    const { store, setStore } = useContext(StoreContext);

    const onSubmitHandler = async (
        values: ObjectType
    ): Promise<ObjectType | void> => {
        if (values.clientId) {
            setStore({ ...store, currentClientId: String(values.clientId) });
            CookiesHelper.setCurrentClientId(
                cookies,
                setCookie,
                String(values.clientId)
            );
            props.setOpen(false);
        }
    };

    return (
        <>
            {store.clients && !store.currentClientId && (
                <Dialog open={props.open}>
                    <DialogContent sx={{ minWidth: 300, maxWidth: 600 }}>
                        <Form
                            onSubmit={onSubmitHandler}
                            render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                invalid
                            }) => (
                                <form
                                    {...form}
                                    id='join-now-form'
                                    onSubmit={handleSubmit}
                                >
                                    <FormTitle text='Select Client' />
                                    <FormTextField
                                        name='clientId'
                                        label='Client'
                                        select
                                        fullWidth
                                        required
                                    >
                                        {Object.keys(store.clients!).map(
                                            (clientId) => (
                                                <MenuItem
                                                    key={clientId}
                                                    value={clientId}
                                                >
                                                    {
                                                        store.clients![clientId]
                                                            ?.name
                                                    }
                                                </MenuItem>
                                            )
                                        )}
                                    </FormTextField>
                                    <FormRowContainer>
                                        <FormButton
                                            disabled={
                                                submitting ||
                                                pristine ||
                                                invalid
                                            }
                                            type='submit'
                                        >
                                            Select
                                        </FormButton>
                                    </FormRowContainer>
                                </form>
                            )}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default SelectClient;
