import CopyAllMui from '@mui/icons-material/CopyAll';

interface CopyAllIconProps {
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const CopyAllIcon = (props: CopyAllIconProps): JSX.Element => {
    return <CopyAllMui {...props} />;
};

export default CopyAllIcon;
