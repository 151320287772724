import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { useContext } from 'react';
import { StoreContext } from '../../../../../contexts/store';
import CookiesHelper from '../../../../../utils/cookies/CookiesHelper';
import { useCookies } from 'react-cookie';
import { CookieNames, CookiesType } from '../../../../../utils/cookies/types';

const ClientsSelect = (): JSX.Element => {
    const [cookies, setCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session,
        CookieNames.UserPreferences
    ]);
    const { store, setStore } = useContext(StoreContext);
    const theme = useTheme();

    const onChangeHandler = (event: SelectChangeEvent) => {
        if (
            event.target.value &&
            event.target.value !== store.currentClientId
        ) {
            CookiesHelper.setCurrentClientId(
                cookies,
                setCookie,
                event.target.value
            );
            setStore({
                ...store,
                currentClientId: event.target.value
            });
        }
    };

    return (
        <>
            {store.clients &&
                Object.keys(store.clients).length &&
                store.currentClientId && (
                    <Select
                        id='id'
                        value={store.currentClientId}
                        sx={{
                            background: theme.colors.alpha.trueWhite[70],
                            mx: theme.spacing(1),
                            mt: theme.spacing(2),
                            width: '94%',
                            height: 40
                        }}
                        onChange={onChangeHandler}
                    >
                        {Object.keys(store.clients!).map((clientId) => (
                            <MenuItem key={clientId} value={clientId}>
                                {store.clients![clientId]?.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
        </>
    );
};

export default ClientsSelect;
