import {
    AuthPermission,
    Modules,
    Operations
} from '@advocate-insights/ms-common';
import { SessionType } from '../../contexts/session';

class AuthHelper {
    public static authorize = (
        session: SessionType,
        permissions: AuthPermission[],
        clientUserOk = true
    ): boolean => {
        let authorized = false;

        if (
            (clientUserOk || !session.user?.clientId) &&
            session.userPermissions
        ) {
            permissions.forEach((perm) => {
                session.userPermissions?.forEach((userPerm) => {
                    if (
                        (userPerm.operationId === perm.operationId ||
                            userPerm.operationId === Operations.All) &&
                        (userPerm.moduleId === perm.moduleId ||
                            userPerm.moduleId === Modules.All)
                    ) {
                        authorized = true;
                    }
                });
            });
        }

        return authorized;
    };
}

export default AuthHelper;
