import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Divider,
    Hidden,
    Popover,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '../../../../Icons/ExpandMoreIcon';
import LockOpenIcon from '../../../../Icons/LockOpenIcon';
import { RoutePaths } from '../../../../core/AppRouter';
import {
    SessionContext,
    SessionContextType
} from '../../../../../contexts/session';
import UserForm from '../../../../../pages/users/components/UserForm';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

// const UserBoxDescription = styled(Typography)(
//     ({ theme }) => `
//         color: ${lighten(theme.palette.secondary.main, 0.5)}
// `
// );

const HeaderUserbox = (): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const [editOpen, setEditOpen] = useState<boolean>(false);

    useEffect(() => {
        if (session.user && session.user.id && !session.user.name) {
            setEditOpen(true);
        }
    }, [session]);

    const ref = useRef(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
                <Avatar variant='rounded' alt={String(session.user?.name)}>
                    {String(session.user?.name).substring(0, 1).toUpperCase()}
                </Avatar>
                <Hidden mdDown>
                    <UserBoxText>
                        <UserBoxLabel variant='body1'>
                            {session.user?.name || ''}
                        </UserBoxLabel>
                        {/* <UserBoxDescription variant='body2'>
                            {user.jobtitle}
                        </UserBoxDescription> */}
                    </UserBoxText>
                </Hidden>
                <Hidden smDown>
                    <ExpandMoreIconStyled />
                </Hidden>
            </UserBoxButton>
            {session.user && (
                <UserForm
                    open={editOpen}
                    setOpen={setEditOpen}
                    title='Edit User'
                    submitButtonText='Save'
                    editId={session.user?.id}
                />
            )}
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuUserBox sx={{ minWidth: 210 }} display='flex'>
                    <Avatar variant='rounded' alt={String(session.user?.name)}>
                        {String(session.user?.name)
                            .substring(0, 1)
                            .toUpperCase()}
                    </Avatar>
                    <UserBoxText>
                        <UserBoxLabel variant='body1'>
                            {session.user?.name || ''}
                        </UserBoxLabel>
                        {/* <UserBoxDescription variant='body2'>
                            {user.jobtitle}
                        </UserBoxDescription> */}
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{ mb: 0 }} />
                {/* <List sx={{ p: 1 }} component='nav'>
                    <ListItem
                        button
                        to='/management/profile/details'
                        component={NavLink}
                    >
                        <AccountBoxIcon fontSize='small' />
                        <ListItemText primary='My Profile' />
                    </ListItem>
                    <ListItem
                        button
                        to='/management/profile/settings'
                        component={NavLink}
                    >
                        <AccountTreeIcon fontSize='small' />
                        <ListItemText primary='Account Settings' />
                    </ListItem>
                </List> */}
                <Divider />
                <Box sx={{ m: 1 }}>
                    <Button
                        component={NavLink}
                        to={`/${RoutePaths.SignOut}`}
                        color='primary'
                        fullWidth
                    >
                        <LockOpenIconStyled />
                        Sign out
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

const ExpandMoreIconStyled = styled(ExpandMoreIcon)({
    marginLeft: '0.5rem'
});

const LockOpenIconStyled = styled(LockOpenIcon)({
    marginRight: '0.5rem'
});

export default HeaderUserbox;
