import { LockOpenTwoTone } from '@mui/icons-material';

// interface LockOpenIconProps {
//   className?: string;
// }

// const LockOpenIcon = (props: LockOpenIconProps) => {
//   return <LockOpenTwoTone className={props.className} />;
// };

// export default LockOpenIcon;
export default LockOpenTwoTone;
