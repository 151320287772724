import CableIconMUI from '@mui/icons-material/Cable';

interface CableIconProps {
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const CableIcon = (props: CableIconProps): JSX.Element => {
    return <CableIconMUI {...props} />;
};

export default CableIcon;
