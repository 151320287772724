import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from '../../pages/NotFound';
import SignOut from '../../pages/auth/SignOut/SignOut';
import PageLayout from '../layout/PageLayout';
import PageLayoutPrivate from '../layout/PageLayoutPrivate/PageLayoutPrivate';
import RedirectPage from '../../pages/trackingLinks/RedirectPage';
import Home from '../../pages/Home/Home';
import Domains from '../../pages/domains/Domains';

// const Home = lazy(() => import('../../pages/Home/Home'));
const SignIn = lazy(() => import('../../pages/auth/SignIn/SignIn'));
const Challenge = lazy(() => import('../../pages/auth/Challenge/Challenge'));
const ForgotPassword = lazy(
    () => import('../../pages/auth/ForgotPassword/ForgotPassword')
);
const Users = lazy(() => import('../../pages/users/Users'));
const Clients = lazy(() => import('../../pages/clients/Clients'));
const Companies = lazy(() => import('../../pages/companies/Companies'));
const People = lazy(() => import('../../pages/people/People'));
const Campaigns = lazy(() => import('../../pages/campaigns/Campaigns'));
const PeopleInCampaign = lazy(
    () => import('../../pages/peopleInCampaign/PeopleInCampaign')
);
const PeopleInCampaignStep = lazy(
    () => import('../../pages/peopleInCampaignStep/PeopleInCampaignStep')
);
const CampaignSteps = lazy(
    () => import('../../pages/campaignSteps/CampaignSteps')
);
const CRMIntegrations = lazy(
    () => import('../../pages/crmIntegrations/CRMIntegrations')
);
const EmailTemplates = lazy(
    () => import('../../pages/emailTemplates/EmailTemplates')
);

export enum RoutePaths {
    SignIn = 'auth/sign-in',
    SignOut = 'auth/sign-out',
    ForgotPassword = 'auth/forgot-password',
    Challenge = 'auth/challenge',
    Users = 'users',
    Clients = 'clients',
    Companies = 'companies',
    Domains = 'domains',
    People = 'people',
    Campaigns = 'campaigns',
    PeopleInCampaign = 'campaigns/people',
    PeopleInCampaignStep = 'campaigns/steps/people',
    CampaignSteps = 'campaigns/steps',
    Survey = 'survey',
    Redirect = 'redir',
    CRMIntegrations = 'crm-integrations',
    EmailTemplates = 'email-templates'
}

export const DEFAULT_LANDING_PAGE = `/${RoutePaths.Campaigns}`;

interface RouteType {
    path: string;
    element: JSX.Element;
}

const publicRoutes: RouteType[] = [
    { path: '/', element: <Home /> },
    { path: RoutePaths.SignIn, element: <SignIn /> },
    { path: RoutePaths.ForgotPassword, element: <ForgotPassword /> },
    { path: RoutePaths.Challenge, element: <Challenge /> },
    { path: RoutePaths.Redirect, element: <RedirectPage /> },
    { path: '*', element: <NotFound /> }
];

const privateRoutes: RouteType[] = [
    { path: RoutePaths.SignOut, element: <SignOut /> },
    { path: RoutePaths.Campaigns, element: <Campaigns /> },
    { path: RoutePaths.PeopleInCampaign, element: <PeopleInCampaign /> },
    {
        path: RoutePaths.PeopleInCampaignStep,
        element: <PeopleInCampaignStep />
    },
    { path: RoutePaths.CampaignSteps, element: <CampaignSteps /> },
    { path: RoutePaths.People, element: <People /> },
    { path: RoutePaths.Users, element: <Users /> },
    { path: RoutePaths.Clients, element: <Clients /> },
    { path: RoutePaths.Domains, element: <Domains /> },
    { path: RoutePaths.Companies, element: <Companies /> },
    { path: RoutePaths.CRMIntegrations, element: <CRMIntegrations /> },
    { path: RoutePaths.EmailTemplates, element: <EmailTemplates /> }
];

const AppRouter = (props: React.PropsWithChildren): JSX.Element => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    {privateRoutes.map((route, idx) => (
                        <Route
                            key={`p${idx}`}
                            path={route.path}
                            element={
                                <PageLayoutPrivate>
                                    {route.element}
                                </PageLayoutPrivate>
                            }
                        />
                    ))}
                    {publicRoutes.map((route, idx) => (
                        <Route
                            key={`u${idx}`}
                            path={route.path}
                            element={<PageLayout>{route.element}</PageLayout>}
                        />
                    ))}
                </Routes>
            </Suspense>
            {props.children}
        </BrowserRouter>
    );
};

export default AppRouter;
