import ClientAPI from '../../../api/ClientAPI';
import IntegrationAPI from '../../../api/IntegrationAPI';
import { SessionType } from '../../../contexts/session';
import { StoreClientsType, StoreType } from '../../../contexts/store';
import { ClientModel } from '../../../models/Client';
import { GrowthBook } from '@growthbook/growthbook-react';
import Logger from '../../../utils/logger';

class AppController {
    public static clientsArrayToObject = (
        clients: ClientModel[]
    ): StoreClientsType => {
        const clientsObj: StoreClientsType = {};

        clients.forEach((cli) => {
            Object.assign(clientsObj, {
                // Returns the same client object without the id property
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                [cli.id]: (({ id, ...noIdCli }) => noIdCli)(cli)
            });
        });

        return clientsObj;
    };

    public static populateStoreWithClients = async (
        session: SessionType,
        store: StoreType,
        setStore: (store: StoreType) => void
    ): Promise<void> => {
        if (session.idToken && !store.clients) {
            try {
                const clients = await ClientAPI.getAll(session);
                setStore({
                    ...store,
                    clients: AppController.clientsArrayToObject(clients)
                });
            } catch (err: unknown) {
                await Logger.error(JSON.stringify(err));
            }
        }
    };

    /**
     * This method depends on store.currentClientId and should be validated
     * before calling it.
     */
    public static refreshStoreConnections = async (
        session: SessionType,
        store: StoreType,
        setStore: (store: StoreType) => void
    ): Promise<void> => {
        if (store.currentClientId && session.idToken) {
            try {
                const connections = await IntegrationAPI.getConsumerConnections(
                    session,
                    String(store.currentClientId)
                );

                if (connections.data instanceof Array) {
                    setStore({ ...store, connections: connections.data });
                }
            } catch (err: unknown) {
                await Logger.error(JSON.stringify(err));
            }
        }
    };

    public static initGrowthBook = (): GrowthBook => {
        return new GrowthBook({
            apiHost: 'https://cdn.growthbook.io',
            clientKey: process.env.REACT_APP_GROWTH_CLIENT_KEY!,
            enableDevMode:
                !process.env.REACT_APP_ENV ||
                process.env.REACT_APP_ENV !== 'production',
            subscribeToChanges: true
        });
    };
}

export default AppController;
