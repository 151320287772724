import axios, {
    AxiosError,
    AxiosInstance,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { ApiErrorObject } from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';
import { ConnectionRecord } from '../models/Connection';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import {
    Contact as APIDeckContact,
    Company as APIDeckCompany
} from '@apideck/node';

export interface ApiContactRecord extends APIDeckContact {}
export interface ApiCompanyRecord extends APIDeckCompany {}
export interface APIDeckVaultSession {
    session_uri: string;
    session_token: string;
}

interface IntegrationApiCompaniesParams {
    unifiedApi: string;
    serviceId: string;
    next?: string;
}

interface IntegrationApiContactsParams extends IntegrationApiCompaniesParams {}

class IntegrationAPI {
    private static getIntegrationAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_INTEGRATION_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static getAPIDeckSession = async (
        session: SessionType,
        clientId: string
    ): Promise<ApiErrorObject | APIDeckVaultSession> => {
        const integrationAPI = this.getIntegrationAPIAxiosObj(session);

        try {
            const sessionResponse = await integrationAPI.post(
                '/integrations/create-session',
                {
                    clientId
                }
            );

            if (
                sessionResponse.status === 200 &&
                sessionResponse.data &&
                sessionResponse.data.session_uri &&
                sessionResponse.data.session_token
            ) {
                return {
                    session_uri: sessionResponse.data.session_uri,
                    session_token: sessionResponse.data.session_token
                };
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError(
                    'creating integration session'
                );
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating integration session');
    };

    public static getConsumerConnections = async (
        session: SessionType,
        consumerId: string
    ): Promise<ApiErrorObject | PaginatedCollection<ConnectionRecord>> => {
        const integrationAPI = this.getIntegrationAPIAxiosObj(session);

        try {
            const response = await integrationAPI.get<
                PaginatedCollection<ConnectionRecord>
            >(`/consumers/${consumerId}/connections`);

            if (response.status === 200 && response.data.data) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError(
                    'getting consumer connections'
                );
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('getting consumer connections');
    };

    public static getConnectionContacts = async (
        session: SessionType,
        consumerId: string,
        connection: ConnectionRecord,
        next?: string
    ): Promise<ApiErrorObject | PaginatedCollection<ApiContactRecord>> => {
        const integrationAPI = this.getIntegrationAPIAxiosObj(session);
        const params: IntegrationApiContactsParams = {
            unifiedApi: connection.unified_api,
            serviceId: connection.service_id
        };

        if (next) {
            params.next = next;
        }

        try {
            const response = await integrationAPI.get<
                PaginatedCollection<ApiContactRecord>
            >(`/consumers/${consumerId}/contacts`, {
                params
            });

            if (response.status === 200 && response.data.data) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('getting consumer contacts');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('getting consumer contacts');
    };

    public static getConnectionCompanies = async (
        session: SessionType,
        consumerId: string,
        connection: ConnectionRecord,
        next?: string
    ): Promise<ApiErrorObject | PaginatedCollection<ApiCompanyRecord>> => {
        const integrationAPI = this.getIntegrationAPIAxiosObj(session);
        const params: IntegrationApiCompaniesParams = {
            unifiedApi: connection.unified_api,
            serviceId: connection.service_id
        };

        if (next) {
            params.next = next;
        }

        try {
            const response = await integrationAPI.get<
                PaginatedCollection<ApiCompanyRecord>
            >(`/consumers/${consumerId}/companies`, { params });

            if (response.status === 200 && response.data.data) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('getting consumer companies');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('getting consumer companies');
    };
}

export default IntegrationAPI;
