import { useContext } from 'react';
import {
    Box,
    Drawer,
    alpha,
    styled,
    Divider,
    useTheme,
    lighten,
    darken,
} from '@mui/material';

import SidebarMenu from './SidebarMenu/SidebarMenu';
import LogoSign from '../../../LogoSign/LogoSign';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import Scrollbar from '../../../common/Scrollbar/Scrollbar';
import ClientsSelect from './ClientsSelect.tsx/ClientsSelect';
import SidebarDivider from './SidebarDivider/SidebarDivider';
import AuthHelper from '../../../../utils/AuthHelper/AuthHelper';
import {
    SessionContext,
    SessionContextType,
} from '../../../../contexts/session';
import { permissions } from '../../../../utils/AuthHelper/types';

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

const Sidebar = (): JSX.Element => {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();
    const { session } = useContext<SessionContextType>(SessionContext);

    return (
        <>
            <SidebarWrapper
                // @todo review later
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'inline-block',
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    background:
                        theme.palette.mode === 'dark'
                            ? alpha(
                                  lighten(theme.header.background ?? '', 0.1),
                                  0.5
                              )
                            : darken(theme.colors.alpha.black[100], 0.5),
                    boxShadow: 'none',
                    // boxShadow:
                    // theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
                }}
            >
                <Scrollbar>
                    <Box mt={3}>
                        <Box
                            mx={2}
                            sx={{
                                width: 52,
                            }}
                        >
                            <LogoSign />
                        </Box>
                    </Box>
                    {AuthHelper.authorize(session, permissions.clientsAny) && (
                        <ClientsSelect />
                    )}
                    <Divider
                        sx={{
                            mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.trueWhite[10],
                        }}
                    />
                    <SidebarMenu />
                </Scrollbar>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`,
                }}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant='temporary'
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.white[100]
                                : darken(theme.colors.alpha.black[100], 0.5),
                    }}
                >
                    <Scrollbar>
                        <Box mt={3}>
                            <Box
                                mx={2}
                                sx={{
                                    width: 52,
                                }}
                            >
                                <LogoSign />
                            </Box>
                        </Box>
                        <SidebarDivider />
                        <SidebarMenu />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
};

export default Sidebar;
