import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    SxProps
} from '@mui/material';
import React from 'react';
import WarningIcon from '../../Icons/WarningIcon';
import VerifiedIcon from '../../Icons/VerifiedIcon';

export interface CustomTableColumn {
    text?: string;
    align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export interface CustomTableRowItem {
    text?: string;
    align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    type?: 'text' | 'icon';
    success?: boolean;
    sx?: SxProps;
}

export interface CustomTableSubRow {
    columns: CustomTableColumn[];
    row: CustomTableRowItem[];
}

export interface CustomTableRow {
    row: CustomTableRowItem[];
    subrow?: CustomTableSubRow[];
}

interface CustomTableProps {
    columns: CustomTableColumn[];
    data: CustomTableRow[];
}

const CustomTable = (props: CustomTableProps): JSX.Element => {
    if (props.columns && props.data) {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ maxHeight: 500 }}>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((value) => (
                                <TableCell align={value.align}>
                                    {value.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((item) => (
                            <Row data={item}></Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return <></>;
};

const Row = (props: { data: CustomTableRow }) => {
    const { data } = props;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {data.row.map((value) => (
                    <TableCell
                        component='th'
                        scope='row'
                        align={value.align}
                        sx={value.sx ?? {}}
                    >
                        {value.text}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0
                    }}
                    colSpan={6}
                >
                    <Table>
                        {data.subrow?.map(() => (
                            <React.Fragment>
                                <TableBody>
                                    {data.subrow?.map((subrow, idx) => (
                                        <TableRow key={idx}>
                                            {subrow?.row.map((value) => (
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                    align={value.align}
                                                    sx={value.sx ?? {}}
                                                >
                                                    {value.type === 'text' ? (
                                                        value.text
                                                    ) : value.success ? (
                                                        <VerifiedIcon
                                                            style={{
                                                                color: 'green'
                                                            }}
                                                        ></VerifiedIcon>
                                                    ) : (
                                                        <WarningIcon
                                                            style={{
                                                                color: 'orange'
                                                            }}
                                                        ></WarningIcon>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </React.Fragment>
                        ))}
                    </Table>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default CustomTable;
