import {
    AuthPermission,
    Modules,
    Operations
} from '@advocate-insights/ms-common';

export interface DecodedToken {
    'custom:userId'?: string;
    userPermissions?: string;
}

interface _PermissionsType {
    clientsAny: AuthPermission[];
    clientsWrite: AuthPermission[];
    clientsDelete: AuthPermission[];
    companiesAny: AuthPermission[];
    companiesWrite: AuthPermission[];
    peopleAny: AuthPermission[];
    peopleWrite: AuthPermission[];
    campaignsAny: AuthPermission[];
    campaignsWrite: AuthPermission[];
    campaignsDelete: AuthPermission[];
    usersAny: AuthPermission[];
    usersWrite: AuthPermission[];
    crmIntegrationsAny: AuthPermission[];
    emailTemplatesWrite: AuthPermission[];
    emailTemplatesAny: AuthPermission[];
    [key: string]: AuthPermission[];
}

export const permissions: _PermissionsType = {
    clientsAny: [{ moduleId: Modules.Clients, operationId: Operations.All }],
    clientsWrite: [
        { moduleId: Modules.Clients, operationId: Operations.Write }
    ],
    clientsDelete: [
        { moduleId: Modules.Clients, operationId: Operations.Delete }
    ],
    companiesAny: [
        { moduleId: Modules.Companies, operationId: Operations.All }
    ],
    companiesWrite: [
        { moduleId: Modules.Companies, operationId: Operations.Write }
    ],
    usersAny: [{ moduleId: Modules.Users, operationId: Operations.All }],
    usersWrite: [{ moduleId: Modules.Users, operationId: Operations.Write }],
    peopleAny: [{ moduleId: Modules.People, operationId: Operations.All }],
    peopleWrite: [{ moduleId: Modules.People, operationId: Operations.Write }],
    campaignsAny: [
        { moduleId: Modules.Campaigns, operationId: Operations.All }
    ],
    campaignsWrite: [
        { moduleId: Modules.Campaigns, operationId: Operations.Write }
    ],
    campaignsDelete: [
        { moduleId: Modules.Campaigns, operationId: Operations.Delete }
    ],
    crmIntegrationsAny: [
        { moduleId: Modules.CRMIntegrations, operationId: Operations.All }
    ],
    emailTemplatesWrite: [
        { moduleId: Modules.EmailTemplates, operationId: Operations.Write }
    ],
    emailTemplatesAny: [
        { moduleId: Modules.EmailTemplates, operationId: Operations.All }
    ]
};
