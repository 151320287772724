import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import { StoreContext } from '../../../contexts/store';

const SignOut = (): JSX.Element => {
    const { setSession } = useContext(SessionContext);
    const [, , removeCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session
    ]);
    const navigate = useNavigate();
    const { setStore } = useContext(StoreContext);

    useEffect(() => {
        setSession({});
        setStore({});
        removeCookie(CookieNames.Session, {
            path: '/'
        });
        navigate('/');
    }, []);

    return <></>;
};

export default SignOut;
