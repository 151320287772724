import { CookieSetOptions } from 'universal-cookie';
import { CookieNames, CookiesType, UserPreferencesType } from './types';

class CookiesHelper {
    public static deletedCurrentClientId = (
        cookies: CookiesType,
        setCookie: (
            name: CookieNames,
            value: unknown,
            options?: CookieSetOptions | undefined
        ) => void
    ): void => {
        const userPreferences: UserPreferencesType = {
            ...(cookies[CookieNames.UserPreferences] ?? {})
        };

        delete userPreferences.currentClientId;

        setCookie(CookieNames.UserPreferences, userPreferences, {
            // path: '/',
            expires: this.unixDateInTenYears(),
            secure: true
        });
    };

    public static setCurrentClientId = (
        cookies: CookiesType,
        setCookies: (
            name: CookieNames,
            value: unknown,
            options?: CookieSetOptions | undefined
        ) => void,
        currentClientId: string
    ): void => {
        setCookies(
            CookieNames.UserPreferences,
            {
                ...cookies[CookieNames.UserPreferences],
                currentClientId
            },
            {
                // path: '/',
                expires: this.unixDateInTenYears(),
                secure: true
            }
        );
    };

    private static unixDateInTenYears = (): Date => {
        return new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10);
    };
}

export default CookiesHelper;
