import CustomDialog from './CustomDialog';
import { DialogTitle, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import { PropsWithChildren } from 'react';

interface ConfirmDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    confirmOptionText?: string;
    confirmButtonDisabled?: boolean;
    cancelOptionText?: string;
    cancelButtonDisabled?: boolean;
    keepOpenAfterConfirm?: boolean;
    onConfirmAction: () => void;
}

const ConfirmDialog = (
    props: PropsWithChildren<ConfirmDialogProps>
): JSX.Element => {
    const onCloseHandler = (): void => {
        props.setOpen(false);
    };

    const onConfirmHandler = () => {
        props.setOpen(props.keepOpenAfterConfirm ?? false);
        props.onConfirmAction();
    };

    return (
        <CustomDialog open={props.open} onClose={onCloseHandler}>
            <DialogTitle>{props.title}</DialogTitle>
            {props.children}
            <DialogActions>
                {Boolean(props.cancelOptionText) && (
                    <Button
                        onClick={onCloseHandler}
                        autoFocus
                        disabled={props.cancelButtonDisabled ?? false}
                    >
                        {props.cancelOptionText || 'No'}
                    </Button>
                )}
                <Button
                    onClick={onConfirmHandler}
                    disabled={props.confirmButtonDisabled ?? false}
                >
                    {props.confirmOptionText || 'Yes'}
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};

export default ConfirmDialog;
