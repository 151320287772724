import { useLocation, useNavigate } from 'react-router-dom';
import CampaignAPI from '../../api/CampaignAPI';
import {
    TrackingLinkCategory,
    trackingLinkCategories
} from '../../models/TrackingLink';
import { useEffect } from 'react';

const RedirectPage = (): JSX.Element => {
    const queryParams = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();

    const evaluateAndRedirect = async (): Promise<void> => {
        /**
         * Trying to keep URLs short, we use some short var names:
         *
         * cid = Campaign ID
         * cat = Category (i.e. "g2")
         * sid = submissionId
         */
        if (!queryParams.has('url') || !queryParams.has('cid')) {
            navigate('/not-found');
        } else {
            const category =
                queryParams.has('cat') &&
                trackingLinkCategories.includes(
                    queryParams.get('cat') as TrackingLinkCategory
                )
                    ? (queryParams.get('cat') as TrackingLinkCategory)
                    : undefined;

            await CampaignAPI.submitTrackingLink(
                String(queryParams.get('cid')),
                String(queryParams.get('url')),
                category,
                queryParams.has('sid')
                    ? String(queryParams.get('sid'))
                    : undefined
            );

            window.location.href = String(queryParams.get('url'));
        }
    };

    useEffect(() => {
        evaluateAndRedirect();
    }, []);

    return <></>;
};

export default RedirectPage;
