import { ExpandMoreTwoTone } from '@mui/icons-material';

// interface ExpandMoreIconProps {
//   className?: string;
//   fontSize?: "small" | "inherit" | "large" | "medium";
// }

// const ExpandMoreIcon = (props: ExpandMoreIconProps) => {
//   return <ExpandMoreTwoTone {...props} />;
// };

// export default ExpandMoreIcon;
export default ExpandMoreTwoTone;
