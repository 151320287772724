import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import { ClientModel, ClientRecord, NewClient } from '../models/Client';
import {
    APIGetPaginatedParams,
    ApiError,
    ApiErrorObject,
    ApiErrorType
} from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';

class ClientAPI {
    private static getClientAPIAxiosObj = (
        session?: SessionType
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_CLIENT_API_BASEURL!;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        clientData: NewClient
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const client = await clientAPI.post<ClientModel>('', clientData);

            if (client.data) {
                return client.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('creating client');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating client');
    };

    public static update = async (
        session: SessionType,
        clientData: ClientRecord
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const updatedClient = await clientAPI.put<ClientModel>(
                `/${clientData.id}`,
                clientData
            );

            if (updatedClient.data) {
                return updatedClient.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('updating client');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating client');
    };

    public static get = async (
        session: SessionType,
        clientId: string
    ): Promise<ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        const { status, data } = await clientAPI.get<ClientModel>(
            `/${clientId}`
        );

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving client');
        }
    };

    public static getMany = async (
        session: SessionType,
        clientId?: string,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<ClientModel>> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params: {} };

        if (clientId) {
            filter.params = { clientId };
        }

        const { status, data } = await clientAPI.get<
            PaginatedCollection<ClientModel>
        >('', {
            params
        });

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving clients');
        }
    };

    public static getAll = async (
        session: SessionType
    ): Promise<ClientModel[]> => {
        let clients: ClientModel[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: APIGetPaginatedParams = {};

        while (processNext && iterationLimit) {
            const clientsPage = await this.getMany(
                session,
                undefined,
                pageParams
            );

            if (clientsPage.count > 0) {
                clients = [...clients, ...clientsPage.data];
            }

            if (!clientsPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = clientsPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'ClientAPI.getAll() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return clients;
    };

    public static delete = async (
        session: SessionType,
        clientId: string
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const deletedClient = await clientAPI.delete<ClientModel>(
                `/${clientId}`
            );

            if (deletedClient.data) {
                return deletedClient.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('deleting client');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('deleting client');
    };

    public static createDomain = async (
        session: SessionType,
        clientId: string,
        domainName: string
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const response = await clientAPI.post<ClientModel>(
                `/${clientId}/domains`,
                { domainName: domainName }
            );

            if (response.data) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('creating domain');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('creating domain');
    };

    public static validateDomain = async (
        session: SessionType,
        clientId: string,
        domainName: string
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const client = await clientAPI.post<ClientModel>(
                `/${clientId}/domains/validate`,
                { domainName }
            );

            if (client.data) {
                return client.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('validate domain');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('validate domain');
    };

    public static deleteDomain = async (
        session: SessionType,
        clientId: string,
        domainName: string
    ): Promise<ApiErrorObject | ClientModel> => {
        const clientAPI = ClientAPI.getClientAPIAxiosObj(session);

        try {
            const response = await clientAPI.delete<ClientModel>(
                `/${clientId}/domains?domainName=${encodeURIComponent(
                    domainName
                )}`
            );

            if (response.data) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('deleting domain');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('deleting domain');
    };
}

export default ClientAPI;
