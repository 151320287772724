import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    PopperPlacementType,
    SxProps,
    TextField,
    Theme
} from '@mui/material';
import { useState } from 'react';
import { Field } from 'react-final-form';
import { ObjectType } from '@advocate-insights/ms-common';
import { isUUIDv1 } from '../../utils/forms/validation';

export interface FieldType extends ObjectType {
    inputValue?: string;
    label: string;
    key?: string;
}

interface FormAutocompleteTextFieldProps {
    // We only supoprt automcomplete for string-based IDs
    initialValue?: string;
    name: string;
    options: FieldType[];
    onEntityCreated?: (value: string) => void;
    label?: string;
    fullWidth?: boolean;
    sx?: SxProps<Theme>;
    minCharsToActivate?: number;
    placement?: PopperPlacementType;
}

const FormAutocompleteTextField = (
    props: FormAutocompleteTextFieldProps
): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const minCharsToActivate = props.minCharsToActivate ?? 3;

    const onInputChangeHandler = (
        value: string,
        reason: AutocompleteInputChangeReason,
        onChange: (value: string) => void
    ) => {
        onChange(value);

        if (reason === 'input') {
            if (props.onEntityCreated) {
                const option = props.options.find(
                    (item) => item.label === value
                );

                if (!option) {
                    props.onEntityCreated(value);
                } else {
                    props.onEntityCreated('');
                }
            }
        }

        if (reason !== 'reset') {
            setOpen(value.length >= minCharsToActivate);
        }
    };

    const onChangeHandler = (
        value: string | FieldType | null | undefined,
        reason: AutocompleteChangeReason,
        onChange: (value: string) => void
    ) => {
        if (value === null && reason === 'clear') {
            onChange('');

            if (props.onEntityCreated) {
                props.onEntityCreated('');
            }
        } else if (
            reason === 'selectOption' &&
            typeof value !== 'string' &&
            value?.key
        ) {
            onChange(value.key);

            if (props.onEntityCreated) {
                props.onEntityCreated('');
            }
        } else if (typeof value === 'string' || !value?.inputValue) {
            console.error('Autocomplete:', value);
        }
    };

    const getAutocompleteValue = (fieldValue?: string): string => {
        if (fieldValue) {
            if (!isUUIDv1(fieldValue)) {
                return fieldValue;
            }

            const option = props.options.find(
                (item) => item.key === fieldValue
            );

            if (option) {
                return option.label;
            }
        }

        return '';
    };

    return (
        <Field name={props.name} initialValue={props.initialValue ?? ''}>
            {(fieldProps) => (
                <Autocomplete
                    value={getAutocompleteValue(fieldProps.input.value)}
                    sx={{ ...props.sx }}
                    fullWidth={props.fullWidth || false}
                    open={open}
                    freeSolo={Boolean(props.onEntityCreated)}
                    options={props.options}
                    onChange={(_, value, reason) =>
                        onChangeHandler(
                            value,
                            reason,
                            fieldProps.input.onChange
                        )
                    }
                    onInputChange={(_, value, reason) =>
                        onInputChangeHandler(
                            value,
                            reason,
                            fieldProps.input.onChange
                        )
                    }
                    isOptionEqualToValue={(option, value) => {
                        return option.key === value.key;
                    }}
                    onClose={() => setOpen(false)}
                    componentsProps={{
                        popper: {
                            style: {
                                width: 'fit-content'
                            },
                            placement: props.placement ?? 'bottom-start',
                            modifiers: [
                                {
                                    name: 'flip',
                                    enabled: false
                                },
                                {
                                    name: 'preventOverflow',
                                    enabled: true
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, props.placement ? 0 : 35]
                                    }
                                }
                            ]
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            disabled={params.disabled}
                            fullWidth={params.fullWidth}
                            inputProps={params.inputProps}
                            InputLabelProps={{ ...params.InputProps }}
                            size='small'
                            label={props.label}
                            {...fieldProps.input}
                        />
                    )}
                />
            )}
        </Field>
    );
};

export default FormAutocompleteTextField;
