import { createContext } from 'react';
import { UserModel } from '../models/User';
import { AuthPermission, ObjectType } from '@advocate-insights/ms-common';

export interface SessionType extends ObjectType {
    idToken?: string;
    accessToken?: string;

    // Session expirarion in seconds
    expiresAt?: number;

    // Logged in user (if available)
    user?: UserModel;

    // User permissions (if user is set)
    userPermissions?: AuthPermission[];
}

/**
 * @deprecated Use SessionType instead
 */
export type Session = SessionType;

export interface SessionContextType {
    session: SessionType;
    setSession: (session: SessionType) => void;
}

export const SessionContext = createContext<SessionContextType>({
    session: {},
    setSession: () => {
        return;
    },
});
