import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import { validateFormValues } from '../../../utils/forms/validation';
import UserAPI from '../../../api/UserAPI';
import { SessionContext, SessionType } from '../../../contexts/session';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import { NewUser, UserModel, UserRecord } from '../../../models/User';
import Overlay from '../../../components/core/Overlay/Overlay';
import FormTitle from '../../../components/forms/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer';
import FormTextField from '../../../components/forms/FormTextField';
import FormButton from '../../../components/forms/FormButton';
import { Modules, ObjectType, Operations } from '@advocate-insights/ms-common';
import { ApiErrorObject } from '../../../api/types';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import { sessionCookieDuration } from '../../../utils/cookies';
import { useCookies } from 'react-cookie';
import { StoreContext } from '../../../contexts/store';
import ClientsController from '../../clients/ClientsController';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import FormAutocompleteTextField from '../../../components/forms/FormAutocompleteTextField';
import { FieldType } from '../../../components/forms/FormAutocompleteTextField';

export interface EditUserInitialValues {
    email?: string;
    name?: string;
    clientId?: string;
}

interface AddUserProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    users?: UserModel[];
    setUsers?: (users: UserModel[]) => void;
    title?: string;
    submitButtonText?: string;
    editId?: string;
    initialValues?: EditUserInitialValues;
}

const UserForm = (props: AddUserProps): JSX.Element => {
    const [processing, setProcessing] = useState<boolean>(false);
    const { session, setSession } = useContext(SessionContext);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [, setCookie] = useCookies<CookieNames, CookiesType>([
        CookieNames.Session
    ]);
    const { store } = useContext(StoreContext);

    const validationObject: {
        [key: string]: yup.StringSchema<string, yup.AnyObject, undefined, ''>;
    } = {
        name: yup.string().min(2).required()
    };

    if (!props.editId) {
        validationObject.email = yup.string().email().required();
    }

    const validate = validateFormValues(yup.object().shape(validationObject));

    useEffect(() => {
        if (
            session.user &&
            props.editId &&
            session.user.id === props.editId &&
            !session.user.name
        ) {
            setAlerts([
                ...alerts,
                {
                    severity: 'info',
                    text: 'Please add a name to your user!'
                }
            ]);
        }
    }, []);

    const onSubmitHandler = async (
        values: ObjectType
    ): Promise<ObjectType | void> => {
        setProcessing(true);
        let user: UserModel | ApiErrorObject;

        const userData: UserRecord = {
            email: String(values.email),
            name: String(values.name),
            permissions: [
                { moduleId: Modules.All, operationId: Operations.All }
            ]
        };

        if (values.clientId) {
            userData.clientId = String(values.clientId);
        } else {
            delete userData.clientId;
        }

        if (props.editId) {
            user = await UserAPI.update(session, props.editId, userData);

            if (
                user &&
                user.id &&
                session.user &&
                user.id === session.user.id
            ) {
                const newSession: SessionType = {
                    ...session,
                    user: user as UserModel
                };

                setSession(newSession);
                setCookie(CookieNames.Session, newSession, {
                    path: '/',
                    expires: new Date(
                        Date.now() + 60000 * sessionCookieDuration
                    ),
                    secure: true
                });
            }
        } else {
            user = await UserAPI.create(session, userData as NewUser);
        }

        if (user && user.id) {
            if (props.users && props.setUsers) {
                props.setUsers([
                    // this trick avoids a duplicate of an editted user
                    user as UserModel,
                    ...props.users.filter(
                        (userItem) =>
                            !props.editId || userItem.id !== props.editId
                    )
                ]);
            }

            setProcessing(false);
            props.setOpen(false);
            return;
        }
        setProcessing(false);
        return user;
    };

    const onCloseHandler = (): void => {
        if (
            !session.user ||
            !props.editId ||
            session.user.id !== props.editId ||
            (session.user.clientId && session.user.name)
        ) {
            props.setOpen(false);
        }
    };

    const getClientValues = (): FieldType[] => {
        if (store.clients) {
            return ClientsController.clientObjectToArray(store.clients).map(
                (cli) => {
                    return {
                        label: cli.name,
                        key: cli.id
                    };
                }
            );
        }
        return [];
    };

    return (
        <CustomDialog open={props.open} onClose={onCloseHandler}>
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                onSubmit={onSubmitHandler}
                validate={validate}
                initialValues={props.initialValues || {}}
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form {...form} id='join-now-form' onSubmit={handleSubmit}>
                        <FormTitle text={props.title || 'Add User'} />
                        <FormRowContainer>
                            <FormTextField
                                name='email'
                                label='Email'
                                fullWidth
                                required
                                autoFocus
                                disabled={Boolean(props.editId)}
                            />
                        </FormRowContainer>
                        <FormRowContainer>
                            <FormTextField
                                name='name'
                                label='Name'
                                fullWidth
                                required
                                autoFocus={!props.editId}
                            />
                        </FormRowContainer>
                        {!session.user?.clientId && (
                            <FormRowContainer>
                                <FormAutocompleteTextField
                                    name='clientId'
                                    label='Client (optional)'
                                    fullWidth
                                    minCharsToActivate={1}
                                    sx={{ mb: 2 }}
                                    options={getClientValues()}
                                    initialValue={
                                        props.initialValues?.clientId ?? ''
                                    }
                                    placement='top-start'
                                />
                            </FormRowContainer>
                        )}
                        <FormRowContainer>
                            <FormButton
                                disabled={submitting || pristine}
                                type='submit'
                            >
                                {props.submitButtonText || 'Add'}
                            </FormButton>
                        </FormRowContainer>
                    </form>
                )}
            />
        </CustomDialog>
    );
};

export default UserForm;
