import { createContext } from 'react';
import { ClientRecord } from '../models/Client';
import { ConnectionRecord } from '../models/Connection';

export interface StoreClientsType {
    [key: string]: ClientRecord;
}

export interface StoreType {
    clients?: StoreClientsType;
    currentClientId?: string;
    connections?: ConnectionRecord[];
}

export interface StoreContextType {
    store: StoreType;
    setStore: (store: StoreType) => void;
}

export const StoreContext = createContext<StoreContextType>({
    store: {},
    setStore: () => {
        return;
    }
});
