import { ObjectType } from '@advocate-insights/ms-common';
import { DataGrid, DataGridProps, GridValidRowModel } from '@mui/x-data-grid';

interface GenericModel extends ObjectType, GridValidRowModel {}

export const PageSize = 20;

const List = <M extends GridValidRowModel = GenericModel>(
    props: DataGridProps<M>
) => {
    const gridProps: DataGridProps<M> = { ...props };

    if (props.rowCount === undefined) {
        gridProps.rowCount = 0;
    }

    if (props.paginationMode === undefined) {
        gridProps.paginationMode = 'server';
    }

    if (props.paginationModel === undefined) {
        gridProps.paginationModel = { pageSize: PageSize, page: 0 };
    }

    return (
        <DataGrid
            sx={{
                border: 0
            }}
            {...gridProps}
        />
    );
};

export default List;
