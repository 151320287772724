import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthHelper from '../utils/AuthHelper/AuthHelper';
import { SessionContext, SessionContextType } from '../contexts/session';
import { AuthPermission } from '@advocate-insights/ms-common';
import { RoutePaths } from '../components/core/AppRouter';

/**
 * This hook will validate if the session user has enough permission or redirect
 *
 * @param permissions Array of AuthPermission with permission to access
 * @param redirect Optional redirect URL if not enough permissions. Defaults to '/'
 */
const usePermissions = (
    permissions: AuthPermission[],
    redirect: string = '/'
) => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const navigate = useNavigate();

    return useEffect(() => {
        if (!AuthHelper.authorize(session, permissions)) {
            if (session.user) {
                navigate(redirect);
            } else {
                navigate(`/${RoutePaths.SignIn}`);
            }
        }
    }, []);
};

export default usePermissions;
