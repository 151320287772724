import { Card as MuiCard, SxProps, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';

interface CardProps {
    className?: string;
    sx?: SxProps<Theme>;
}

const Card = (props: PropsWithChildren<CardProps>) => {
    return <MuiCard {...props} />;
};

export default Card;
