import { CookieSetOptions } from 'universal-cookie';
import { SessionType } from '../../contexts/session';

export enum CookieNames {
    Session = 'sess',
    UserPreferences = '_up',
    // Notifications = 'notif',
}

export interface UserPreferencesType {
    currentClientId?: string;
}

export type CookiesType = {
    [CookieNames.Session]?: SessionType;
    [CookieNames.UserPreferences]?: UserPreferencesType;
    // [CookieNames.Notifications]?: number;
};

export type SetUserPreferencesCookieType = (
    name: CookieNames.UserPreferences,
    value: UserPreferencesType,
    options?: CookieSetOptions | undefined
) => void;

export type SetSessionCookieType = (
    name: CookieNames.Session,
    value: SessionType,
    options?: CookieSetOptions | undefined
) => void;

export type SetCookieType = SetSessionCookieType | SetUserPreferencesCookieType;

// From useCookies hook (react-cookie library)
export type ReactCookieType = [
    CookiesType,
    SetCookieType,
    (name: CookieNames, options?: CookieSetOptions | undefined) => void,
    () => void
];

// export enum CookieNotificationWeight {
//     Intro = 1,
// }
