import { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import * as yup from 'yup';
import { validateFormValues } from '../../../utils/forms/validation';
import { SessionContext } from '../../../contexts/session';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import Overlay from '../../../components/core/Overlay/Overlay';
import FormTitle from '../../../components/forms/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer';
import FormTextField from '../../../components/forms/FormTextField';
import FormButton from '../../../components/forms/FormButton';
import ClientAPI from '../../../api/ClientAPI';
import { ObjectType, ValidationRegex } from '@advocate-insights/ms-common';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import usePermissions from '../../../hooks/usePermissions';
import { permissions } from '../../../utils/AuthHelper/types';
import { DomainModel, NewDomain } from '../../../models/Domain';

const validate = validateFormValues(
    yup.object().shape({
        domainName: yup
            .string()
            .min(5)
            .required()
            .matches(ValidationRegex.Domain, 'Enter a valid domain')
    })
);

interface DomainFormProps {
    clientId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    title?: string;
    submitButtonText?: string;
    onItemAddedHandler?:
        | ((domain: DomainModel) => Promise<void>)
        | (() => void);
}

const DomainForm = (props: DomainFormProps): JSX.Element => {
    usePermissions(permissions.clientsWrite);

    const [processing, setProcessing] = useState<boolean>(false);
    const { session } = useContext(SessionContext);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);

    const onSubmitHandler = async (
        values: ObjectType
    ): Promise<ObjectType | void> => {
        setProcessing(true);

        const domainData: NewDomain = {
            domainName: String(values.domainName)
        };

        const client = await ClientAPI.createDomain(
            session,
            props.clientId,
            domainData.domainName
        );

        if (client && client.senderDomains) {
            if (props.onItemAddedHandler) {
                await props.onItemAddedHandler(domainData);
            }
            setProcessing(false);
            props.setOpen(false);
            return;
        }

        setProcessing(false);
        return client;
    };

    const onCloseHandler = (): void => {
        props.setOpen(false);
    };

    return (
        <CustomDialog open={props.open} onClose={onCloseHandler}>
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                onSubmit={onSubmitHandler}
                validate={validate}
                render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    invalid
                }) => (
                    <form {...form} id='join-now-form' onSubmit={handleSubmit}>
                        <FormTitle text={props.title || 'Add Domain'} />
                        <FormRowContainer>
                            <FormTextField
                                name='domainName'
                                label='Domain Name'
                                fullWidth
                                required
                                autoFocus
                            />
                        </FormRowContainer>
                        <FormRowContainer>
                            <FormButton
                                disabled={submitting || pristine || invalid}
                                type='submit'
                            >
                                {props.submitButtonText || 'Add Domain'}
                            </FormButton>
                        </FormRowContainer>
                    </form>
                )}
            />
        </CustomDialog>
    );
};

export default DomainForm;
