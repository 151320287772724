import { useState, createContext, PropsWithChildren } from 'react';
type SidebarContext = {
    // sidebarToggle: any;
    sidebarToggle: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
    {} as SidebarContext
);

export const SidebarProvider = (props: PropsWithChildren) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };
    const closeSidebar = () => {
        setSidebarToggle(false);
    };

    return (
        <SidebarContext.Provider
            value={{ sidebarToggle, toggleSidebar, closeSidebar }}
        >
            {props.children}
        </SidebarContext.Provider>
    );
};
