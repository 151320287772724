import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    RawAxiosRequestHeaders
} from 'axios';
import { SessionType } from '../contexts/session';
import { PaginatedCollection } from '@advocate-insights/ms-common';
import {
    APIGetPaginatedParams,
    ApiError,
    ApiErrorObject,
    ApiErrorType
} from './types';
import {
    apiErrorsToConsolidatedErrorObject,
    generateApiUnexpectedError
} from './utils/data-management';
import {
    CampaignModel,
    CampaignRecord,
    NewCampaign,
    PersonInCampaignRecord
} from '../models/Campaign';
import { TrackingLinkCategory } from '../models/TrackingLink';
import Logger from '../utils/logger';

export enum SelectRuleLogic {
    Equal = 'equal',
    GreaterThan = 'greaterThan',
    Exists = 'exists'
}

export interface SelectRule {
    property: string;
    logic: SelectRuleLogic;
    value?: string | number;
}

interface TrackingLinkPostBody {
    campaignId: string;
    url: string;
    category?: TrackingLinkCategory;
    submissionId?: string;
}

class CampaignAPI {
    private static getCampaignAPIAxiosObj = (
        session?: SessionType,
        basePath: string = '/campaigns'
    ): AxiosInstance => {
        const headers: RawAxiosRequestHeaders = {
            'Content-Type': 'application/json'
        };

        if (session) {
            headers.Authorization = `Bearer ${session.idToken}`;
        }

        const baseURL = process.env.REACT_APP_CAMPAIGN_API_BASEURL! + basePath;

        return axios.create({
            baseURL,
            headers
        });
    };

    public static create = async (
        session: SessionType,
        campaignData: NewCampaign
    ): Promise<ApiErrorObject | CampaignModel> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const campaign = await campaignAPI.post<CampaignModel>(
                '',
                campaignData
            );

            if (campaign.data) {
                return campaign.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('creating campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating campaign');
    };

    public static update = async (
        session: SessionType,
        campaignData: CampaignRecord
    ): Promise<ApiErrorObject | CampaignModel> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const updatedCampaign = await campaignAPI.put<CampaignModel>(
                `/${campaignData.id}`,
                campaignData
            );

            if (updatedCampaign.data) {
                return updatedCampaign.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data.errors instanceof Array) ||
                !err.response.data.errors.length
            ) {
                return generateApiUnexpectedError('updating campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data.errors);
        }

        return generateApiUnexpectedError('creating campaign');
    };

    public static get = async (
        session: SessionType,
        campaignId: string
    ): Promise<CampaignModel> => {
        const userAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        const { status, data } = await userAPI.get<CampaignModel>(
            `/${campaignId}`
        );

        if (status === 200 && data && data.id) {
            return data;
        } else {
            throw new Error(`Error retrieving campaign with id ${campaignId}`);
        }
    };

    public static getMany = async (
        session: SessionType,
        clientId?: string,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<CampaignModel>> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);
        const filter: AxiosRequestConfig = { params: {} };

        if (clientId) {
            filter.params = { clientId };
        }

        if (params) {
            filter.params = { ...filter.params };
            if (params.scanLimit) {
                filter.params.scanLimit = params.scanLimit;
            }
            if (params.lastKey) {
                filter.params.lastKey = JSON.stringify(params.lastKey);
            }
        }

        const { status, data } = await campaignAPI.get<
            PaginatedCollection<CampaignModel>
        >('', filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving campaigns');
        }
    };

    public static getAll = async (
        session: SessionType,
        clientId: string
    ): Promise<CampaignModel[]> => {
        let campaigns: CampaignModel[] = [];
        const processNext = true;
        let iterationLimit = 20;
        const pageParams: APIGetPaginatedParams = {};

        while (processNext && iterationLimit) {
            const companyPage = await this.getMany(
                session,
                clientId,
                pageParams
            );

            if (companyPage.count > 0) {
                campaigns = [...campaigns, ...companyPage.data];
            }

            if (!companyPage.lastKey) {
                break;
            }

            iterationLimit--;

            pageParams.lastKey = companyPage.lastKey;
        }

        if (iterationLimit <= 0) {
            throw new ApiError(
                'CampaignAPI.getAll() reached its iteration limit',
                ApiErrorType.IterationLimit
            );
        }

        return campaigns;
    };

    /**
     * This method will leave the designated campaign with the provided people
     * assigned to it. The API will take care of any addition and deletion needed.
     *
     * Because this method returns an array of IDs (string) of unprocessed people,
     * an empty array is the undisputed confirmation of the process completing 100%.
     *
     * @param session
     * @param campaignId
     * @param people Array of people IDs
     *
     * @returns An array of person IDs of people which could not be processed or an error object
     */
    public static assignPeople = async (
        session: SessionType,
        campaignId: string,
        people: string[]
    ): Promise<ApiErrorObject | string[]> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.put(
                `/${campaignId}/people`,
                people
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.unprocessedPeople
            ) {
                return response.data.unprocessedPeople;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError(
                    'assigning people to campaign'
                );
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('assigning people to campaign');
    };

    public static addPeople = async (
        session: SessionType,
        campaignId: string,
        people: string[]
    ): Promise<ApiErrorObject | string[]> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post(
                `/${campaignId}/people`,
                people
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.unprocessedPeople
            ) {
                return response.data.unprocessedPeople;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('adding people to campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('adding people to campaign');
    };

    public static getPeople = async (
        session: SessionType,
        campaignId?: string,
        params: APIGetPaginatedParams = {}
    ): Promise<PaginatedCollection<PersonInCampaignRecord>> => {
        const filter: AxiosRequestConfig = {
            params
        };
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        if (params.lastKey) {
            filter.params.lastKey = JSON.stringify(params.lastKey);
        }

        const { status, data } = await campaignAPI.get<
            PaginatedCollection<PersonInCampaignRecord>
        >(`/${campaignId}/people`, filter);

        if (status === 200 && data) {
            return data;
        } else {
            throw new Error('Error retrieving people in campaign');
        }
    };

    public static start = async (
        session: SessionType,
        campaignId: string
    ): Promise<ApiErrorObject | CampaignModel> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post<CampaignModel>(
                `/${campaignId}/start`
            );

            if (response.status === 200 && response.data && response.data.id) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('starting campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('starting campaign');
    };

    public static finish = async (
        session: SessionType,
        campaignId: string
    ): Promise<ApiErrorObject | CampaignModel> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post<CampaignModel>(
                `/${campaignId}/finish`
            );

            if (response.status === 200 && response.data && response.data.id) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('finishing campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('finishing campaign');
    };

    public static clone = async (
        session: SessionType,
        campaignId: string,
        sourceCampaignId: string
    ): Promise<ApiErrorObject | string[]> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post(
                `/${campaignId}/clone-people?sourceCampaignId=${sourceCampaignId}`
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.unprocessedPeople
            ) {
                return response.data.unprocessedPeople;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('cloning campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('cloning campaign');
    };

    public static smartSelect = async (
        session: SessionType,
        campaignId: string,
        sourceCampaignId: string,
        rules: SelectRule[]
    ): Promise<ApiErrorObject | string[]> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post(
                `/${campaignId}/people-select?sourceCampaignId=${sourceCampaignId}`,
                rules
            );

            if (
                response.status === 200 &&
                response.data &&
                response.data.unprocessedPeople
            ) {
                return response.data.unprocessedPeople;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError(
                    'smart selecting people in campaign'
                );
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('smart selecting people in campaign');
    };

    public static submitTrackingLink = async (
        campaignId: string,
        url: string,
        category?: TrackingLinkCategory,
        submissionId?: string
    ): Promise<void> => {
        const campaignAPI = this.getCampaignAPIAxiosObj(
            undefined,
            '/tracking-links'
        );

        try {
            const body: TrackingLinkPostBody = {
                campaignId,
                url
            };

            if (category) {
                body.category = category;
            }

            if (submissionId) {
                body.submissionId = submissionId;
            }

            const response = await campaignAPI.post('', body);

            if (response.status !== 200) {
                Logger.error(
                    `Submitting tracking link: ${JSON.stringify(response)}`
                );
            }
        } catch (err: unknown) {
            Logger.error(`Submitting tracking link: ${JSON.stringify(err)}`);
        }
    };

    public static archive = async (
        session: SessionType,
        campaignId: string
    ): Promise<ApiErrorObject | CampaignModel> => {
        const campaignAPI = CampaignAPI.getCampaignAPIAxiosObj(session);

        try {
            const response = await campaignAPI.post<CampaignModel>(
                `/${campaignId}/archive`
            );

            if (response.status === 200 && response.data && response.data.id) {
                return response.data;
            }
        } catch (err: unknown) {
            if (
                !(err instanceof AxiosError) ||
                !err.response ||
                err.response.status !== 400 ||
                !(err.response.data instanceof Array) ||
                !err.response.data.length
            ) {
                return generateApiUnexpectedError('archiving campaign');
            }

            return apiErrorsToConsolidatedErrorObject(err.response.data);
        }

        return generateApiUnexpectedError('archiving campaign');
    };
}

export default CampaignAPI;
