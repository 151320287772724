import { AuthPermission } from '@advocate-insights/ms-common';
import { useContext } from 'react';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import AuthHelper from '../../../utils/AuthHelper/AuthHelper';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';

interface ActionCellProps {
    icon: JSX.Element;
    record: GridRowParams;
    enablePermissions: AuthPermission[];
    onClickHandler: (record: GridRowParams) => void;
    disabled?: boolean;
}

const ActionCell = (props: ActionCellProps): JSX.Element => {
    const { session } = useContext<SessionContextType>(SessionContext);
    const enableEdit =
        props.enablePermissions &&
        AuthHelper.authorize(session, props.enablePermissions) &&
        props.onClickHandler;

    return (
        <>
            {enableEdit && (
                <GridActionsCellItem
                    disabled={Boolean(props.disabled)}
                    icon={props.icon}
                    label='Edit'
                    sx={{ color: 'primary.main' }}
                    onClick={() => props.onClickHandler!(props.record)}
                />
            )}
        </>
    );
};

export default ActionCell;
