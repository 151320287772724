import { DomainModel } from '../../models/Domain';
import { ClientDomainRecordLocal } from '../../models/Client';
import {
    CustomTableColumn,
    CustomTableRow
} from '../../components/common/Table/CustomTable';

export interface DomainResult {
    sendgridMetadata?: SendgridMetadata;
    validated: boolean;
    sendgridValidationResult?: SendgridValidationResult;
    domainName: string;
}

export interface SendgridMetadata {
    valid: boolean;
    domain: string;
    dns: Dns;
}

export interface Dns {
    mail_cname?: MailCname;
    dkim1?: MailCname;
    dkim2?: MailCname;
    dmarc?: MailCname;
}

export interface MailCname {
    valid?: boolean;
    host?: string;
    type?: string;
    data?: string;
    reason?: string;
    data_with_valimail?: string;
}

export interface SendgridValidationResult {
    mail_cname?: MailCname;
    dkim1?: MailCname;
    dkim2?: MailCname;
    dmarc?: MailCname;
}

class DomainHelper {
    static buildTableDataDomainMetadata = (
        senderDomains: ClientDomainRecordLocal[] | undefined,
        selectedDomain: string
    ) => {
        const res = DomainHelper.parseDomainData(senderDomains, selectedDomain);
        if (res) {
            const columns: CustomTableColumn[] = [
                { text: 'TYPE', align: 'center' },
                { text: 'HOST', align: 'left' },
                { text: 'STATUS', align: 'right' },
                { text: 'VALUE', align: 'left' }
            ];

            const rows: CustomTableRow[] = [
                {
                    row: [
                        {
                            text:
                                res.sendgridMetadata?.dns?.mail_cname?.type?.toUpperCase() ??
                                '',
                            align: 'center'
                        },
                        {
                            text:
                                res.sendgridMetadata?.dns?.mail_cname?.host?.toUpperCase() ??
                                '',
                            align: 'left'
                        },
                        {
                            text:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.mail_cname?.valid
                                    ? 'Verified'
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.mail_cname
                                          ?.valid
                                    ? 'Failed'
                                    : 'Pending',
                            align: 'right',
                            sx:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.mail_cname?.valid
                                    ? { color: 'green' }
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.mail_cname
                                          ?.valid
                                    ? { color: 'red' }
                                    : { color: 'gray', fontWeight: 'bold' }
                        },
                        {
                            text:
                                res.sendgridMetadata?.dns?.mail_cname?.data ??
                                '',
                            align: 'left'
                        }
                    ],
                    subrow: [
                        {
                            row:
                                !res.sendgridValidationResult ||
                                !res.sendgridValidationResult.mail_cname ||
                                res.sendgridValidationResult.mail_cname.valid
                                    ? []
                                    : [
                                          {
                                              text: res.sendgridValidationResult
                                                  .mail_cname.valid
                                                  ? 'Verified'
                                                  : 'Failed',
                                              align: 'right',
                                              type: 'icon',
                                              success:
                                                  res.sendgridValidationResult
                                                      .mail_cname.valid ??
                                                  false,
                                              sx: { textAlign: 'start' }
                                          },
                                          {
                                              text:
                                                  res.sendgridValidationResult
                                                      .mail_cname.reason ?? '',
                                              align: 'center',
                                              type: 'text',
                                              sx: res.sendgridValidationResult
                                                  .mail_cname.valid
                                                  ? { color: 'lightgreen' }
                                                  : {
                                                        color: 'darkorange',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        textAlign: 'start',
                                                        width: '95%'
                                                    }
                                          }
                                      ],
                            columns: [
                                { text: 'VALID', align: 'right' },
                                { text: 'REASON', align: 'center' }
                            ]
                        }
                    ]
                },
                {
                    row: [
                        {
                            text:
                                res.sendgridMetadata?.dns?.dkim1?.type?.toUpperCase() ??
                                '',
                            align: 'center'
                        },
                        {
                            text:
                                res.sendgridMetadata?.dns?.dkim1?.host?.toUpperCase() ??
                                '',
                            align: 'left'
                        },
                        {
                            text:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dkim1?.valid
                                    ? 'Verified'
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dkim1?.valid
                                    ? 'Failed'
                                    : 'Pending',
                            align: 'right',
                            sx:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dkim1?.valid
                                    ? { color: 'green' }
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dkim1?.valid
                                    ? { color: 'red' }
                                    : { color: 'gray', fontWeight: 'bold' }
                        },
                        {
                            text: res.sendgridMetadata?.dns?.dkim1?.data ?? '',
                            align: 'left'
                        }
                    ],
                    subrow: [
                        {
                            row:
                                !res.sendgridValidationResult ||
                                !res.sendgridValidationResult.dkim1 ||
                                res.sendgridValidationResult.dkim1.valid
                                    ? []
                                    : [
                                          {
                                              text: res.sendgridValidationResult
                                                  .dkim1.valid
                                                  ? 'Verified'
                                                  : 'Failed',
                                              align: 'right',
                                              type: 'icon',
                                              success:
                                                  res.sendgridValidationResult
                                                      .dkim1.valid ?? false,
                                              sx: { textAlign: 'start' }
                                          },
                                          {
                                              text:
                                                  res.sendgridValidationResult
                                                      .dkim1.reason ?? '',
                                              align: 'center',
                                              type: 'text',
                                              sx: res.sendgridValidationResult
                                                  .dkim1.valid
                                                  ? { color: 'lightgreen' }
                                                  : {
                                                        color: 'darkorange',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        textAlign: 'start',
                                                        width: '95%'
                                                    }
                                          }
                                      ],
                            columns: [
                                { text: 'VALID', align: 'right' },
                                { text: 'REASON', align: 'center' }
                            ]
                        }
                    ]
                },
                {
                    row: [
                        {
                            text:
                                res.sendgridMetadata?.dns?.dkim2?.type?.toUpperCase() ??
                                '',
                            align: 'center'
                        },
                        {
                            text:
                                res.sendgridMetadata?.dns?.dkim2?.host?.toUpperCase() ??
                                '',
                            align: 'left'
                        },
                        {
                            text:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dkim2?.valid
                                    ? 'Verified'
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dkim2?.valid
                                    ? 'Failed'
                                    : 'Pending',
                            align: 'right',
                            sx:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dkim2?.valid
                                    ? { color: 'green' }
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dkim2?.valid
                                    ? { color: 'red' }
                                    : { color: 'gray', fontWeight: 'bold' }
                        },
                        {
                            text: res.sendgridMetadata?.dns?.dkim2?.data ?? '',
                            align: 'left'
                        }
                    ],
                    subrow: [
                        {
                            row:
                                !res.sendgridValidationResult ||
                                !res.sendgridValidationResult.dkim2 ||
                                res.sendgridValidationResult.dkim2.valid
                                    ? []
                                    : [
                                          {
                                              text: res.sendgridValidationResult
                                                  .dkim2.valid
                                                  ? 'Verified'
                                                  : 'Failed',
                                              align: 'right',
                                              type: 'icon',
                                              success:
                                                  res.sendgridValidationResult
                                                      .dkim2.valid ?? false,
                                              sx: { textAlign: 'start' }
                                          },
                                          {
                                              text:
                                                  res.sendgridValidationResult
                                                      .dkim2.reason ?? '',
                                              align: 'center',
                                              type: 'text',
                                              sx: res.sendgridValidationResult
                                                  .dkim2.valid
                                                  ? { color: 'lightgreen' }
                                                  : {
                                                        color: 'darkorange',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        textAlign: 'start',
                                                        width: '95%'
                                                    }
                                          }
                                      ],
                            columns: [
                                { text: 'VALID', align: 'right' },
                                { text: 'REASON', align: 'center' }
                            ]
                        }
                    ]
                },
                {
                    row: [
                        {
                            text:
                                res.sendgridMetadata?.dns?.dmarc?.type?.toUpperCase() ??
                                '',
                            align: 'center'
                        },
                        {
                            text:
                                res.sendgridMetadata?.dns?.dmarc?.host?.toUpperCase() ??
                                '',
                            align: 'left'
                        },
                        {
                            text:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dmarc?.valid
                                    ? 'Verified'
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dmarc?.valid
                                    ? 'Failed'
                                    : 'Pending',
                            align: 'right',
                            sx:
                                res.sendgridValidationResult &&
                                res.sendgridValidationResult?.dmarc?.valid
                                    ? { color: 'green' }
                                    : res.sendgridValidationResult &&
                                      !res.sendgridValidationResult.dmarc?.valid
                                    ? { color: 'red' }
                                    : { color: 'gray', fontWeight: 'bold' }
                        },
                        {
                            text: res.sendgridMetadata?.dns?.dmarc?.data ?? '',
                            align: 'left'
                        }
                    ],
                    subrow: [
                        {
                            row:
                                !res.sendgridValidationResult ||
                                !res.sendgridValidationResult.dmarc ||
                                res.sendgridValidationResult.dmarc.valid
                                    ? []
                                    : [
                                          {
                                              text: res.sendgridValidationResult
                                                  .dmarc.valid
                                                  ? 'Verified'
                                                  : 'Failed',
                                              align: 'right',
                                              type: 'icon',
                                              success:
                                                  res.sendgridValidationResult
                                                      .dmarc.valid ?? false,
                                              sx: { textAlign: 'start' }
                                          },
                                          {
                                              text:
                                                  res.sendgridValidationResult
                                                      .dmarc.reason ?? '',
                                              align: 'center',
                                              type: 'text',
                                              sx: res.sendgridValidationResult
                                                  .dmarc.valid
                                                  ? { color: 'lightgreen' }
                                                  : {
                                                        color: 'darkorange',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        textAlign: 'start',
                                                        width: '95%'
                                                    }
                                          }
                                      ],
                            columns: [
                                { text: 'VALID', align: 'right' },
                                { text: 'REASON', align: 'center' }
                            ]
                        }
                    ]
                }
            ];

            return { columns, rows };
        }
        return {};
    };
    static isDomainValidationResultValid = (
        senderDomains: ClientDomainRecordLocal[],
        selectedDomain: string
    ) => {
        const res = DomainHelper.parseDomainData(senderDomains, selectedDomain);
        return res && res.validated;
    };

    static domainMetadataNotPresent = (
        senderDomains: DomainModel[] | undefined,
        selectedDomain: string
    ): boolean => {
        const senderDomain = DomainHelper.findDomain(
            senderDomains,
            selectedDomain
        );
        if (!senderDomain) {
            return false;
        }
        if (senderDomain && senderDomain.sendgridMetadata) {
            return false;
        }
        return true;
    };

    static findDomain = (
        senderDomains: DomainModel[] | undefined,
        domain: string
    ) => {
        if (!senderDomains) {
            return undefined;
        }
        if (senderDomains) {
            return senderDomains.find((value) => domain === value.domainName);
        }
        return undefined;
    };

    static parseDomainData = (
        senderDomains: DomainModel[] | undefined,
        selectedDomain: string
    ): DomainResult | undefined => {
        const senderDomain = DomainHelper.findDomain(
            senderDomains,
            selectedDomain
        );
        if (senderDomain && senderDomain.sendgridMetadata) {
            const res: DomainResult = JSON.parse(JSON.stringify(senderDomain));
            return res;
        }
        return undefined;
    };
}

export default DomainHelper;
