import ReactDOM from 'react-dom/client';
import './index.css';
import { envVarOk } from './utils/sanity';
import App from './components/core/App/App';

if (envVarOk()) {
    if (window.location.hostname === 'www.advocate-insights.io') {
        window.location.replace(
            `https://advocate-insights.io${window.location.pathname}`
        );
    } else {
        const root = ReactDOM.createRoot(
            document.getElementById('root') as HTMLElement
        );
        root.render(
            <>
                <App />
            </>
        );
    }
}
