import { InitiateAuthResponse } from '@aws-sdk/client-cognito-identity-provider';
import { createContext } from 'react';

export interface ProcessesType {
    signin?: InitiateAuthResponse;
    redirect?: string;
    signinMsg?: string;
}

/**
 * @deprecated Use ProcessesType instead
 */
export type Processes = ProcessesType;

export interface ProcessesContextType {
    processes: ProcessesType;
    setProcesses: (processes: ProcessesType) => void;
}

export const ProcessesContext = createContext<ProcessesContextType>({
    processes: {},
    setProcesses: () => {
        return;
    },
});
