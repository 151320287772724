import { Link, Breadcrumbs as MUIBreadcrums, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface BreadcrumbOption {
    text: string;
    link?: string;
}

interface BreadcrumbsProps {
    options: BreadcrumbOption[];
}

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
    const BCOptions: JSX.Element[] = props.options.map(
        (option): JSX.Element => {
            return option.link ? (
                <Link
                    component={RouterLink}
                    underline='hover'
                    color='inherit'
                    to={option.link}
                    key={option.text}
                >
                    {option.text}
                </Link>
            ) : (
                <Typography color='text.primary' key={option.text}>
                    {option.text}
                </Typography>
            );
        }
    );

    return <MUIBreadcrums sx={{ mb: 2 }}>{BCOptions}</MUIBreadcrums>;
};

export default Breadcrumbs;
