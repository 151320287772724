import * as yup from 'yup';
import { setIn } from 'final-form';
import { ObjectType } from '@advocate-insights/ms-common';

export const validateFormValues =
    (schema: yup.AnySchema) =>
    async (values: ObjectType): Promise<ObjectType | undefined> => {
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (err: unknown) {
            if (err instanceof yup.ValidationError) {
                const errors = err.inner.reduce(
                    (
                        formError: ObjectType,
                        innerError: yup.ValidationError
                    ) => {
                        return setIn(
                            formError,
                            String(innerError.path),
                            innerError.message
                        );
                    },
                    {}
                );

                return errors;
            }
        }

        return;
    };

export const isUUIDv1 = (value: string): boolean => {
    return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
        value
    );
};
