import { AddTwoTone } from '@mui/icons-material';

// interface AddIconProps {
//   fontSize?: "small" | "inherit" | "large" | "medium";
// }

// const AddIcon = (props: AddIconProps) => {
//   return <AddTwoTone fontSize={props.fontSize} />;
// };

// export default AddIcon;
export default AddTwoTone;
