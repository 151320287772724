import { StoreClientsType } from '../../contexts/store';
import { ClientModel } from '../../models/Client';

class ClientsController {
    public static clientObjectToArray = (
        clientsObj: StoreClientsType
    ): ClientModel[] => {
        return Object.keys(clientsObj).map(
            (key): ClientModel => ({
                ...clientsObj[key],
                id: key,
                name: String(clientsObj[key]?.name),
            })
        );
    };
}

export default ClientsController;
