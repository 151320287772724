import { Divider, useTheme } from '@mui/material';

const SidebarDivider = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Divider
            sx={{
                m: theme.spacing(2),
                mt: theme.spacing(3),
                background: theme.colors.alpha.trueWhite[10],
            }}
        />
    );
};

export default SidebarDivider;
