import { useContext } from 'react';
import { alpha, Box, List, styled, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from '../../../../../contexts/SidebarContext';
import { RoutePaths } from '../../../../core/AppRouter';
import BusinessIcon from '../../../../Icons/BusinessIcon';
import CampaignIcon from '../../../../Icons/CampaignIcon';
import ContactMailIcon from '../../../../Icons/ContactMailIcon';
import InventoryIcon from '../../../../Icons/InventoryIcon';
import PeopleAltIcon from '../../../../Icons/PeopleAltIcon';
import SidebarDivider from '../SidebarDivider/SidebarDivider';
import {
    SessionContext,
    SessionContextType
} from '../../../../../contexts/session';
import AuthHelper from '../../../../../utils/AuthHelper/AuthHelper';
import { permissions } from '../../../../../utils/AuthHelper/types';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import CableIcon from '../../../../Icons/CableIcon';
import CopyAllIcon from '../../../../Icons/CopyAllIcon';

const SidebarMenu = (): JSX.Element => {
    const { closeSidebar } = useContext(SidebarContext);
    const { session } = useContext<SessionContextType>(SessionContext);

    return (
        <>
            <MenuWrapper>
                <List component='div'>
                    <SubMenuWrapper>
                        <List component='div'>
                            {AuthHelper.authorize(
                                session,
                                permissions.campaignsAny
                            ) && (
                                <ListItem component='div'>
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={`/${RoutePaths.Campaigns}`}
                                        startIcon={<CampaignIcon />}
                                    >
                                        Campaigns
                                    </Button>
                                </ListItem>
                            )}
                            {AuthHelper.authorize(
                                session,
                                permissions.peopleAny
                            ) && (
                                <ListItem component='div'>
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={`/${RoutePaths.People}`}
                                        startIcon={<ContactMailIcon />}
                                    >
                                        Contacts
                                    </Button>
                                </ListItem>
                            )}
                            {AuthHelper.authorize(
                                session,
                                permissions.companiesAny
                            ) && (
                                <ListItem component='div'>
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={`/${RoutePaths.Companies}`}
                                        startIcon={<BusinessIcon />}
                                    >
                                        Companies
                                    </Button>
                                </ListItem>
                            )}
                            {AuthHelper.authorize(
                                session,
                                permissions.emailTemplatesAny
                            ) && (
                                <IfFeatureEnabled feature='email-templates'>
                                    <ListItem component='div'>
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to={`/${RoutePaths.EmailTemplates}`}
                                            startIcon={<CopyAllIcon />}
                                        >
                                            Email Templates
                                        </Button>
                                    </ListItem>
                                </IfFeatureEnabled>
                            )}
                            <SidebarDivider />
                            {AuthHelper.authorize(
                                session,
                                permissions.crmIntegrationsAny
                            ) && (
                                <IfFeatureEnabled feature='crm-integrations'>
                                    <ListItem component='div'>
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to={`/${RoutePaths.CRMIntegrations}`}
                                            startIcon={<CableIcon />}
                                        >
                                            CRM Integrations
                                        </Button>
                                    </ListItem>
                                </IfFeatureEnabled>
                            )}{' '}
                            {AuthHelper.authorize(
                                session,
                                permissions.clientsAny
                            ) && (
                                <ListItem component='div'>
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={`/${RoutePaths.Clients}`}
                                        startIcon={<InventoryIcon />}
                                    >
                                        Clients
                                    </Button>
                                </ListItem>
                            )}
                            {AuthHelper.authorize(
                                session,
                                permissions.usersAny
                            ) && (
                                <ListItem component='div'>
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={`/${RoutePaths.Users}`}
                                        startIcon={<PeopleAltIcon />}
                                    >
                                        Users
                                    </Button>
                                </ListItem>
                            )}
                        </List>
                    </SubMenuWrapper>
                </List>
            </MenuWrapper>
        </>
    );
};

const MenuWrapper = styled(Box)(
    ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                    'transform',
                    'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

export default SidebarMenu;
