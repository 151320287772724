import ReactDOM from 'react-dom';

const checkEnvVar = (envVar: string): boolean => {
    if (!process.env[envVar]) {
        console.error(`${envVar} not defined as environment variable`);
        ReactDOM.render(
            <>Environment variables not defined</>,
            document.getElementById('root')
        );

        return false;
    }

    return true;
};

export const envVarOk = (): boolean => {
    return (
        checkEnvVar('REACT_APP_USER_API_BASEURL') &&
        checkEnvVar('REACT_APP_CLIENT_API_BASEURL') &&
        checkEnvVar('REACT_APP_COMPANY_API_BASEURL') &&
        checkEnvVar('REACT_APP_PERSON_API_BASEURL') &&
        checkEnvVar('REACT_APP_CAMPAIGN_API_BASEURL') &&
        checkEnvVar('REACT_APP_SURVEY_RESPONSE_API_BASEURL') &&
        checkEnvVar('REACT_APP_COGNITO_CLIENT_ID') &&
        checkEnvVar('REACT_APP_COGNITO_REGION') &&
        checkEnvVar('REACT_APP_CAMPAIGN_STEPS_API_BASEURL') &&
        checkEnvVar('REACT_APP_SUBMISSION_API_BASEURL') &&
        checkEnvVar('REACT_APP_SURVEY_TEMPLATE_API_BASEURL') &&
        checkEnvVar('REACT_APP_CAMPAIGN_METRICS_API_BASEURL') &&
        checkEnvVar('REACT_APP_GROWTH_CLIENT_KEY') &&
        checkEnvVar('REACT_APP_ENV') &&
        checkEnvVar('REACT_APP_SURVEY_URL')
    );
};
