import { MenuTwoTone } from '@mui/icons-material';

// interface MenuIconProps {
//   fontSize?: "small" | "inherit" | "large" | "medium";
// }

// const MenuIcon = (props: MenuIconProps) => {
//   return <MenuTwoTone fontSize={props.fontSize} />;
// };

// export default MenuIcon;
export default MenuTwoTone;
