import { useLocation, useNavigate } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { permissions } from '../../utils/AuthHelper/types';
import { RoutePaths } from '../../components/core/AppRouter';
import { useContext, useEffect, useState } from 'react';
import Alerts, { AlertObj } from '../../components/Alerts/Alerts';
import { SessionContext, SessionContextType } from '../../contexts/session';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import Breadcrumbs from '../../components/common/Breadcrumbs/Breadcrumbs';
import DomainsHeader from './components/DomainsHeader';
import { ClientModel } from '../../models/Client';
import Card from '../../components/common/Card/Card';
import List from '../../components/common/List/List';
import ClientAPI from '../../api/ClientAPI';
import VerifiedIcon from '../../components/Icons/VerifiedIcon';
import Logger from '../../utils/logger';
import Button from '@mui/material/Button';
import ActionCell from '../../components/common/ActionCell/ActionCell';
import DeleteForeverIcon from '../../components/Icons/DeleteForeverIcon';
import ConfirmDialog from '../../components/common/CustomDialog/ConfirmDialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText, styled } from '@mui/material';
import { DomainModel } from '../../models/Domain';
import DomainValidation from './components/DomainValidation';

const Domains = (): JSX.Element => {
    usePermissions(permissions.clientsAny);

    const { session } = useContext<SessionContextType>(SessionContext);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [client, setClient] = useState<ClientModel>();
    const [selectedDomain, setSelectedDomain] = useState<string>('');
    const [openValidateDialog, setOpenValidateDialog] =
        useState<boolean>(false);

    const queryParams = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const clientId = queryParams.get('id');

    const [selectedDomainForDeletion, setSelectedDomainForDeletion] =
        useState<DomainModel>();
    const [openConfirmDeleteDomain, setOpenConfirmDeleteDomain] =
        useState<boolean>(false);

    if (!clientId) {
        navigate(`/${RoutePaths.Clients}`);
    }

    const refreshClientState = async (): Promise<ClientModel | undefined> => {
        let res: ClientModel | undefined;
        if (clientId) {
            res = await ClientAPI.get(session, clientId);
        }
        return res;
    };

    const getClient = async (): Promise<void> => {
        setClient(await refreshClientState());
    };

    useEffect(() => {
        getClient();
    }, []);

    const onDeleteClickHandler = async (record: GridRowParams) => {
        const selectedDomain = client?.senderDomains?.find(
            (value) => value.domainName === record.row.domainName
        );
        if (selectedDomain) {
            setSelectedDomainForDeletion(selectedDomain);
            setOpenConfirmDeleteDomain(true);
        }
    };

    const onConfirmDeleteClickHandler = async () => {
        setLoading(true);
        try {
            if (
                selectedDomainForDeletion &&
                selectedDomainForDeletion.domainName
            ) {
                const res = await ClientAPI.deleteDomain(
                    session,
                    clientId!,
                    selectedDomainForDeletion?.domainName
                );
                setClient(res as ClientModel);
            }
        } catch (error: unknown) {
            const errorId = await Logger.error(JSON.stringify(error));
            setAlerts([
                ...alerts,
                {
                    severity: 'error',
                    text: `[${errorId}]: Unknown error removing domain. Please try again or contact support.`
                }
            ]);
        } finally {
            getClient();
            setLoading(false);
        }
    };

    const onItemAddedHandler = () => {
        getClient();
    };

    const onValidationSucceeded = () => {
        getClient();
    };

    const onVerifyDomainClickHandler = (domainName: string) => {
        setSelectedDomain(domainName);
        setOpenValidateDialog(true);
    };

    const onClosedHandler = () => {
        getClient();
    };

    const columns: GridColDef[] = [
        { field: 'domainName', headerName: 'Domain Name', flex: 1 },
        {
            field: 'validated',
            headerName: 'Verified',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => {
                if (params.row.validated) {
                    return (
                        <VerifiedIcon style={{ color: 'green' }}></VerifiedIcon>
                    );
                }
                return (
                    <Button
                        sx={{ ml: 1 }}
                        variant='contained'
                        onClick={() =>
                            onVerifyDomainClickHandler(params.row.domainName)
                        }
                    >
                        Validate
                    </Button>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 150,
            align: 'center',
            getActions: (record: GridRowParams) => {
                const actions: JSX.Element[] = [];
                actions.push(
                    <ActionCell
                        icon={<DeleteForeverIcon />}
                        record={record}
                        enablePermissions={permissions.campaignsDelete}
                        onClickHandler={onDeleteClickHandler}
                    />
                );
                return actions;
            }
        }
    ];

    return (
        <Container>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Breadcrumbs
                options={[
                    { text: 'Home', link: '/' },
                    { text: 'Clients', link: `/${RoutePaths.Clients}` },
                    { text: 'Domains' }
                ]}
            />
            <DomainsHeader
                client={client}
                onItemAddedHandler={onItemAddedHandler}
            />
            <Card sx={{ height: '100%' }}>
                <List
                    columns={columns}
                    rows={client?.senderDomains ?? []}
                    loading={loading}
                    getRowId={(row) => row.domainName}
                />
            </Card>
            <DomainValidation
                open={openValidateDialog}
                setOpen={setOpenValidateDialog}
                setSelectedDomain={setSelectedDomain}
                onValidationSucceeded={onValidationSucceeded}
                onClosed={onClosedHandler}
                senderDomains={client?.senderDomains ?? []}
                selectedDomain={selectedDomain}
                clientId={clientId ?? ''}
            ></DomainValidation>
            <ConfirmDialog
                open={openConfirmDeleteDomain}
                setOpen={setOpenConfirmDeleteDomain}
                title=''
                cancelOptionText='No'
                confirmOptionText='Yes'
                onConfirmAction={onConfirmDeleteClickHandler}
            >
                <DialogContent>
                    <DialogContentText>
                        {`Do you want to delete domain ${selectedDomainForDeletion?.domainName}?`}
                    </DialogContentText>
                </DialogContent>
            </ConfirmDialog>
        </Container>
    );
};

const Container = styled('div')({
    marginTop: '1rem',
    padding: '0 2rem',
    height: '100%'
});

export default Domains;
