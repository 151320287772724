import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ProcessesContext, ProcessesType } from '../../../contexts/processes';
import { SessionType, SessionContext } from '../../../contexts/session';
import { CookieNames, CookiesType } from '../../../utils/cookies/types';
import AppRouter, { RoutePaths } from '../AppRouter';
import ThemeProvider from '../theme/ThemeProvider';
import { SidebarProvider } from '../../../contexts/SidebarContext';
import { StoreContext, StoreType } from '../../../contexts/store';
import AppController from './AppController';
import SelectClient from '../../../modals/SelectClient';
import Overlay from '../Overlay/Overlay';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import CookiesHelper from '../../../utils/cookies/CookiesHelper';

const growthbook = AppController.initGrowthBook();

const App = (): JSX.Element => {
    const [cookies, setCookie, removeCookie] = useCookies<
        CookieNames,
        CookiesType
    >([CookieNames.Session, CookieNames.UserPreferences]);
    const [session, setSession] = useState<SessionType>(
        cookies[CookieNames.Session] ?? {}
    );
    const [processes, setProcesses] = useState<ProcessesType>({});
    const [store, setStore] = useState<StoreType>({});
    const [tout, setTout] = useState<ReturnType<typeof setTimeout>>();
    const [cliModalOpen, setCliModalOpen] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    // Clear session context if session expire
    useEffect(() => {
        if (session.expiresAt && !tout) {
            const timeout = session.expiresAt - Date.now();

            setTout(
                setTimeout(() => {
                    if (session.idToken) {
                        removeCookie(CookieNames.Session);
                        setSession({});
                        setStore({});
                    }
                }, timeout)
            );
        } else if (!session.expiresAt) {
            setTout(undefined);
        }
    }, [session]);

    // Populate store with clients
    const populateStoreWithClients = useCallback(async () => {
        setProcessing(true);
        await AppController.populateStoreWithClients(session, store, setStore);
        setProcessing(false);
    }, [session.idToken, store.clients]);

    useEffect(() => {
        populateStoreWithClients();
    }, [session.idToken, store.clients]);

    // Clear currentClientId if not supported
    const clearCurrentClientIdIfNotSupported = useCallback(() => {
        if (
            cookies[CookieNames.UserPreferences]?.currentClientId &&
            session.user?.clientId &&
            cookies[CookieNames.UserPreferences]?.currentClientId !==
                session.user.clientId
        ) {
            CookiesHelper.deletedCurrentClientId(cookies, setCookie);
            const newStore = { ...store };
            delete newStore.currentClientId;
            setStore(newStore);
        }
    }, [store, session.user, cookies[CookieNames.UserPreferences]]);

    useEffect(() => {
        clearCurrentClientIdIfNotSupported();
    }, [store, session.user, cookies[CookieNames.UserPreferences]]);

    // Redirect to clients if no clients in store
    useEffect(() => {
        if (
            store.clients &&
            Object.keys(store.clients).length <= 0 &&
            location.pathname !== `/${RoutePaths.Clients}`
        ) {
            window.location.href = `/${RoutePaths.Clients}`;
        }
    }, [store.clients, location]);

    // Auto select current client if only one client
    useEffect(() => {
        if (
            store.clients &&
            Object.keys(store.clients).length === 1 &&
            typeof Object.keys(store.clients)[0] === 'string' &&
            store.currentClientId !== Object.keys(store.clients)[0] &&
            (!session.user?.clientId ||
                session.user?.clientId === Object.keys(store.clients)[0])
        ) {
            setStore({
                ...store,
                currentClientId: String(Object.keys(store.clients)[0])
            });
            CookiesHelper.setCurrentClientId(
                cookies,
                setCookie,
                String(Object.keys(store.clients)[0])
            );
        }
    }, [store.clients, store.currentClientId, session.user]);

    // Auto select current client if 2+ clients and current client available
    useEffect(() => {
        if (
            store.clients &&
            Object.keys(store.clients).length > 1 &&
            cookies[CookieNames.UserPreferences] &&
            cookies[CookieNames.UserPreferences].currentClientId &&
            (!session.user?.clientId ||
                session.user?.clientId ===
                    cookies[CookieNames.UserPreferences].currentClientId) &&
            store.clients[
                cookies[CookieNames.UserPreferences].currentClientId
            ] &&
            store.currentClientId !==
                cookies[CookieNames.UserPreferences].currentClientId
        ) {
            setStore({
                ...store,
                currentClientId:
                    cookies[CookieNames.UserPreferences].currentClientId
            });
            CookiesHelper.setCurrentClientId(
                cookies,
                setCookie,
                cookies[CookieNames.UserPreferences].currentClientId
            );
        }
    }, [
        store.clients,
        store.currentClientId,
        cookies[CookieNames.UserPreferences],
        session.user
    ]);

    // Open current client selector dialog if 2+ clients and no current client available
    useEffect(() => {
        if (
            store.clients &&
            Object.keys(store.clients).length > 1 &&
            (!cookies[CookieNames.UserPreferences] ||
                !cookies[CookieNames.UserPreferences].currentClientId)
        ) {
            setCliModalOpen(true);
        }
    }, [store.clients, cookies[CookieNames.UserPreferences]]);

    // Populate client's connections into store
    useEffect(() => {
        if (
            session.idToken &&
            store.currentClientId &&
            store.currentClientId ===
                cookies[CookieNames.UserPreferences]?.currentClientId &&
            (!session.user?.clientId ||
                session.user?.clientId === store.currentClientId)
        ) {
            setProcessing(true);
            AppController.refreshStoreConnections(
                session,
                store,
                setStore
            ).then(() => {
                setProcessing(false);
            });
        }
    }, [session.idToken, store.currentClientId]);

    // Load features asynchronously when the app renders
    useEffect(() => {
        growthbook.loadFeatures();
    }, []);

    return (
        <>
            <Overlay processing={processing} no-icon />
            <SessionContext.Provider value={{ session, setSession }}>
                <StoreContext.Provider value={{ store, setStore }}>
                    <ProcessesContext.Provider
                        value={{ processes, setProcesses }}
                    >
                        <SidebarProvider>
                            <ThemeProvider>
                                <GrowthBookProvider growthbook={growthbook}>
                                    <AppRouter />
                                </GrowthBookProvider>
                            </ThemeProvider>
                        </SidebarProvider>
                    </ProcessesContext.Provider>
                    <SelectClient
                        open={cliModalOpen}
                        setOpen={setCliModalOpen}
                    />
                </StoreContext.Provider>
            </SessionContext.Provider>
        </>
    );
};

export default App;
