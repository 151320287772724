import React, { ReactNode, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ThemeMapType, themeCreator } from './base';

type ThemeContextType = (themeName: keyof ThemeMapType) => void;

export const ThemeContext = React.createContext<ThemeContextType>(
    (): void => {}
);

const ThemeProviderWrapper: React.FC<{ children: ReactNode }> = (props) => {
    // const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const curThemeName = 'PureLightTheme';
    const [themeName, _setThemeName] =
        useState<keyof ThemeMapType>(curThemeName);
    const theme = themeCreator(themeName);
    const setThemeName = (themeName: keyof ThemeMapType): void => {
        localStorage.setItem('appTheme', themeName);
        _setThemeName(themeName);
    };

    return (
        <ThemeContext.Provider value={setThemeName}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProviderWrapper;
