// import { LoggerAPI } from '../api/LoggerAPI';
import { v4 as uuidv4 } from 'uuid';

type ErrorSeverity = 'error' | 'debug' | 'warning' | 'info';

const Logger = {
    error: async (msg: string): Promise<string> => Logger.log('error', msg),
    debug: async (msg: string): Promise<string> => Logger.log('debug', msg),
    warning: async (msg: string): Promise<string> => Logger.log('warning', msg),
    info: async (msg: string): Promise<string> => Logger.log('info', msg),

    /**
     * This should be refactored in the future to incorporate logic and a call
     * to an external logger.
     *
     * @param _severity Severity of the log entry
     * @param _msg Message to log
     * @returns Promise to allow asynchronous logging (avoiding UX interferance)
     */
    log: async (severity: ErrorSeverity, msg: string): Promise<string> => {
        // return new Promise((resolve, reject): void => {
        return new Promise((resolve): void => {
            const uuid = uuidv4();
            if (
                !process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development'
            ) {
                console.log(`${severity} [${uuid}]: ${msg}`);
                resolve(uuid);
            } else {
                // LoggerAPI.submitEntry({
                //     type: severity === 'info' ? 'log' : severity,
                //     message: `[${uuid}]: ${msg}`,
                // }),
                console.log(msg);
                resolve(uuid);
            }
        });
    },
};

export default Logger;
