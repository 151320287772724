import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const PageLayout = (props: PropsWithChildren): JSX.Element => {
    return <Container>{props.children}</Container>;
};

const Container = styled('div')({
    flex: 1,
    margin: '2rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

export default PageLayout;
