import VerifiedTwoTone from '@mui/icons-material/VerifiedTwoTone';

interface VerifiedIconProps {
    style: React.CSSProperties;
}

const VerifiedIcon = (props: VerifiedIconProps): JSX.Element => {
    return <VerifiedTwoTone style={props.style} />;
};

export default VerifiedIcon;
