import { Typography } from '@mui/material';

const Home = (): JSX.Element => {
    return (
        <Typography variant='h3' sx={{ marginTop: '5rem' }}>
            Advocate Insights helps technology companies collect reviews,
            testimonials, and other feedback from their users.
        </Typography>
    );
};

export default Home;
