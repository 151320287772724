import { useContext, useEffect, useState } from 'react';
import Alerts, { AlertObj } from '../../../components/Alerts/Alerts';
import Overlay from '../../../components/core/Overlay/Overlay';
import CustomDialog from '../../../components/common/CustomDialog/CustomDialog';
import usePermissions from '../../../hooks/usePermissions';
import { permissions } from '../../../utils/AuthHelper/types';
import CustomTable, {
    CustomTableColumn,
    CustomTableRow
} from '../../../components/common/Table/CustomTable';
import DomainHelper from '../../../utils/DomainHelper/DomainHelper';
import { ClientDomainRecordLocal } from '../../../models/Client';
import { Form } from 'react-final-form';
import FormTitle from '../../../components/forms/FormTitle';
import FormRowContainer from '../../../components/forms/FormRowContainer';
import FormButton from '../../../components/forms/FormButton';
import ClientAPI from '../../../api/ClientAPI';
import { SessionContext, SessionContextType } from '../../../contexts/session';
import Logger from '../../../utils/logger';

interface DomainValidationProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setSelectedDomain: (domain: string) => void;
    onValidationSucceeded: () => void;
    onClosed: () => void;
    title?: string;
    senderDomains: ClientDomainRecordLocal[];
    selectedDomain: string;
    submitButtonText?: string;
    clientId: string;
}

const DomainValidation = (props: DomainValidationProps): JSX.Element => {
    usePermissions(permissions.clientsWrite);

    const { session } = useContext<SessionContextType>(SessionContext);
    const [processing, setProcessing] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertObj[]>([]);
    const [columns, setColumns] = useState<CustomTableColumn[]>([]);
    const [rows, setRows] = useState<CustomTableRow[]>([]);

    useEffect(() => {
        if (props.selectedDomain && props.senderDomains) {
            const { columns, rows } = DomainHelper.buildTableDataDomainMetadata(
                props.senderDomains,
                props.selectedDomain
            );
            if (columns && columns.length > 0 && rows && rows.length > 0) {
                setColumns(columns);
                setRows(rows);
            }
        }
    }, [props.selectedDomain, props.senderDomains]);

    const validateDomain = async () => {
        setProcessing(true);
        try {
            const response = await ClientAPI.validateDomain(
                session,
                props.clientId,
                props.selectedDomain
            );
            if (
                response &&
                response.id &&
                response.name &&
                response.senderDomains
            ) {
                return response.senderDomains as ClientDomainRecordLocal[];
            }
        } catch (error: unknown) {
            const errorId = await Logger.error(JSON.stringify(error));
            setAlerts([
                ...alerts,
                {
                    severity: 'error',
                    text: `[${errorId}]: Unknown error validating domain. Please try again or contact support.`
                }
            ]);
        } finally {
            setProcessing(false);
        }
        return [];
    };

    const onSubmitHandler = async (): Promise<void> => {
        setProcessing(true);
        const senderDomains = await validateDomain();
        try {
            if (
                DomainHelper.isDomainValidationResultValid(
                    senderDomains,
                    props.selectedDomain
                )
            ) {
                props.onValidationSucceeded();
                props.setOpen(false);
                return;
            }
            if (senderDomains.length > 0) {
                const { columns, rows } =
                    DomainHelper.buildTableDataDomainMetadata(
                        senderDomains,
                        props.selectedDomain
                    );
                if (columns && columns.length > 0 && rows && rows.length > 0) {
                    setColumns(columns);
                    setRows(rows);
                }
            }
        } finally {
            setProcessing(false);
        }
    };

    const onCloseHandler = (): void => {
        props.setSelectedDomain('');
        props.setOpen(false);
        props.onClosed();
    };

    return (
        <CustomDialog
            open={props.open}
            onClose={onCloseHandler}
            fullWidth
            maxWidth='md'
        >
            <Overlay processing={processing} />
            <Alerts alerts={alerts} setAlerts={setAlerts} />
            <Form
                onSubmit={onSubmitHandler}
                render={({ handleSubmit, form, submitting }) => (
                    <form {...form} id='join-now-form' onSubmit={handleSubmit}>
                        <FormTitle text={props.title || 'Domain Validation'} />
                        <FormRowContainer>
                            <CustomTable
                                columns={columns}
                                data={rows}
                            ></CustomTable>
                        </FormRowContainer>
                        <FormRowContainer padding='10px' textAlign='right'>
                            <FormButton disabled={submitting} type='submit'>
                                {props.submitButtonText || 'Validate Domain'}
                            </FormButton>
                        </FormRowContainer>
                    </form>
                )}
            />
        </CustomDialog>
    );
};

export default DomainValidation;
