import { CloseTwoTone } from '@mui/icons-material';

// interface CloseIconProps {
//   fontSize?: "small" | "inherit" | "large" | "medium";
// }

// const CloseIcon = (props: CloseIconProps) => {
//   return <CloseTwoTone fontSize={props.fontSize} />;
// };

// export default CloseIcon;
export default CloseTwoTone;
