import NewReleasesTwoTone from '@mui/icons-material/NewReleasesTwoTone';

interface WarningIconProps {
    style: React.CSSProperties;
}

const WarningIcon = (props: WarningIconProps): JSX.Element => {
    return <NewReleasesTwoTone style={props.style} />;
};

export default WarningIcon;
